import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

function FloatingWidget({ setExpertIdInput, experts = [] }) {
  const [items, setItems] = useState([]);
  const [opacity, setOpacity] = useState('20%');
  const [isMinimized, setIsMinimized] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState('');

  useEffect(() => {
    const mappedItems = experts.map((expert) => ({
      name: expert.expertName,
      value: expert.expertId,
    }));
    setItems(mappedItems);

    // Extract chat ID from URL to create a unique storage key
    const url = window.location.pathname;
    const chatId = url.split('/').pop(); // Gets the last part of the URL path
    const storageKey = `selectedExpertId_${chatId}`;
    
    // Get previously selected expert from localStorage using the chat-specific key
    const savedExpertId = localStorage.getItem(storageKey);
    
    // Check if the saved expert exists in the current list
    const expertExists = mappedItems.some(item => item.value === savedExpertId);
    
    if (expertExists && savedExpertId) {
      // If the saved expert exists in the current list, select it
      setExpertIdInput(savedExpertId);
      setSelectedExpert(savedExpertId);
    } else if (mappedItems.length > 0 && !selectedExpert) {
      // Otherwise, select the first expert by default if none is selected
      setExpertIdInput(mappedItems[0].value);
      setSelectedExpert(mappedItems[0].value);
      // Save the selected expert to localStorage with chat-specific key
      localStorage.setItem(storageKey, mappedItems[0].value);
    }
  }, [experts, selectedExpert, setExpertIdInput]);

  const handleMouseOver = () => {
    setOpacity('100%'); // Full opacity when mouse is over
  };

  const handleMouseOut = () => {
    setOpacity('20%'); // Return to default opacity when mouse is out
  };

  const handleLoadItem = (item) => {
    setExpertIdInput(item.value);
    setSelectedExpert(item.value); // highlight the selected expert
    // Extract chat ID from URL for the storage key
    const url = window.location.pathname;
    const chatId = url.split('/').pop();
    const storageKey = `selectedExpertId_${chatId}`;
    
    // Save the selected expert to localStorage with chat-specific key
    localStorage.setItem(storageKey, item.value);
  };

  const handleClearItems = () => {
    setItems([]);
    setExpertIdInput('');
    setSelectedExpert('');
    // Extract chat ID from URL for the storage key
    const url = window.location.pathname;
    const chatId = url.split('/').pop();
    const storageKey = `selectedExpertId_${chatId}`;
    
    // Clear the saved expert from localStorage using the chat-specific key
    localStorage.removeItem(storageKey);
  };

  const toggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  return (
    <Box
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      sx={{
        position: 'fixed',
        bottom: '70px',
        right: '5px',
        width: isMinimized ? 150 : 380,
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: '8px',
        boxShadow: 3,
        opacity,
        zIndex: 9999,
      }}
    >
      {/* Minimize/Expand Icon */}
      <IconButton
        onClick={() => {
          toggleMinimize();
          handleMouseOut(); // Return to default opacity after clicking
        }}
        sx={{ position: 'absolute', top: -8, right: isMinimized ? 140 : 370, zIndex: 9999 }}
      >
        {isMinimized ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      {isMinimized ? (
        <Typography variant="body2" align="center" >
          Experts Widget
        </Typography>
      ) : (
        <>
          {/* Scrollable list with no horizontal overflow */}
          <List
            sx={{
              maxHeight: 200,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {items.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '8px',
                  marginBottom: '4px',
                  bgcolor:
                    selectedExpert === item.value ? 'lightblue' : 'inherit',
                  border:
                    selectedExpert === item.value ? '2px solid blue' : 'none',
                  borderRadius: '4px',
                }}
              >
                {/* Name and ID in a single column (could use grid or flex) */}
                <Box sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
                  {/* Name */}
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      fontWeight: 'bold',
                      whiteSpace: 'normal',       // Allow wrapping
                      overflowWrap: 'break-word', // Break words at arbitrary points if needed
                      wordBreak: 'break-all'      // Forces breaks even in the middle of words
                    }}
                  >
                    {item.name}
                  </Typography>
                  {/* ID */}
                  <Typography variant="caption" noWrap>
                    {item.value}
                  </Typography>
                </Box>

                {/* Load Button */}
                <Button
                  onClick={() => handleLoadItem(item)}
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2, whiteSpace: 'nowrap' }}
                >
                  Load
                </Button>
              </ListItem>
            ))}
          </List>

          {/* Clear All Button */}
          {/* <Button
            onClick={handleClearItems}
            variant="contained"
            color="secondary"
            sx={{ mt: 2, width: '100%' }}
          >
            Clear All
          </Button> */}
        </>
      )}
    </Box>
  );
}

export default FloatingWidget;