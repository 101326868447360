import React from 'react';
import { List, Drawer, ListItemButton, ListItemIcon, ListItemText, Divider, ListItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FolderIcon from '@mui/icons-material/Folder';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ImageIcon from '@mui/icons-material/Image';
import SchemaIcon from '@mui/icons-material/Schema';

export default function Sidebar({ handleNavigation, AccessList, currentOrgRole, userData }) {

  const handleMouseClick = (event, path) => {
    if (event.ctrlKey || event.button === 1) { // Middle mouse button
      window.open(path, '_blank');
    } else if (event.button === 0) { // Left mouse button
      handleNavigation(path);
    }
  };

  return (
    <Drawer variant="permanent" sx={{ [`& .MuiDrawer-paper`]: { mt: '64px', zIndex: 1 } }}>
      <List>
        <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, '/dashboard');}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, '/dashboard');} else {handleNavigation('/dashboard');}}}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, '/account');}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, '/account');} else {handleNavigation('/account');}}}>
          <ListItemIcon><AccountBoxIcon /></ListItemIcon>
          <ListItemText primary="Account" />
        </ListItemButton>
        
        {AccessList.Everyone.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, '/chats');}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, '/chats');} else {handleNavigation('/chats');}}}>
            <ListItemIcon><ChatIcon /></ListItemIcon>
            <ListItemText primary="Chats" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <>
            <Divider />
            <ListItem><ListItemText primary="Organization" /></ListItem>
          </>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations/${userData.currentOrganization}/modules`);}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations/${userData.currentOrganization}/modules`);} else {handleNavigation(`/organizations/${userData.currentOrganization}/modules`);}}}>
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary="Modules" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations/${userData.currentOrganization}/experts`);}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations/${userData.currentOrganization}/experts`);} else {handleNavigation(`/organizations/${userData.currentOrganization}/experts`);}}}>
            <ListItemIcon><EngineeringIcon /></ListItemIcon>
            <ListItemText primary="Experts" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations/${userData.currentOrganization}/finetuning`);}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations/${userData.currentOrganization}/finetuning`);} else {handleNavigation(`/organizations/${userData.currentOrganization}/finetuning`);}}}>
            <ListItemIcon><RateReviewIcon /></ListItemIcon>
            <ListItemText primary="Finetuning" />
          </ListItemButton>
        )}


        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations/${userData.currentOrganization}/files`);}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations/${userData.currentOrganization}/files`);} else {handleNavigation(`/organizations/${userData.currentOrganization}/files`);}}}>
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary="Files" />
          </ListItemButton>
        )}

        {/* {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/images`)}>
          <ListItemIcon><ImageIcon /></ListItemIcon>
          <ListItemText primary="Images" />
          </ListItemButton>
          )}
          
          {AccessList.Editors.includes(currentOrgRole) && (
            <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/objects`)}>
            <ListItemIcon><ViewInArIcon /></ListItemIcon>
            <ListItemText primary="Objects" />
            </ListItemButton>
            )} */}

        {AccessList.Owners.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations/${userData.currentOrganization}/users`);}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations/${userData.currentOrganization}/users`);} else {handleNavigation(`/organizations/${userData.currentOrganization}/users`);}}}>
            <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        )}

        {AccessList.Owners.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations/${userData.currentOrganization}/schemas`);}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations/${userData.currentOrganization}/schemas`);} else {handleNavigation(`/organizations/${userData.currentOrganization}/schemas`);}}}>
            <ListItemIcon><SchemaIcon /></ListItemIcon>
            <ListItemText primary="Schemas" />
          </ListItemButton>
        )}
        
        {AccessList.Owners.includes(currentOrgRole) && (
          <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/settings`);}}}
          onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/settings`);} else {handleNavigation(`/settings`);}}}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        )}

        {userData?.superUser && (
          <>
            <Divider />
            <ListItem><ListItemText primary="Admin" /></ListItem>

            <ListItemButton onMouseDownCapture={(e) => {e.preventDefault()}} onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, `/organizations`);}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, `/organizations`);} else {handleNavigation(`/organizations`);}}}>
              <ListItemIcon><BusinessIcon /></ListItemIcon>
              <ListItemText primary="Organizations" />
            </ListItemButton>
          </>
        )}

      </List>
    </Drawer>
  );
}