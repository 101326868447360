// MainLayout.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserProvider';
import { ConfigContext } from './ConfigProvider';
import Sidebar from './Components/Sidebar';
import TopBar from './Components/TopBar';
import { getOrganization } from './Utilities/apiConnector'; // New API connector import
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { Roles } from './ConfigProvider';

function MainLayout({ children }) {
  const { user, userData, loading, organization } = useContext(UserContext);
  const { AccessList } = useContext(ConfigContext);
  const [organizationName, setOrganizationName] = useState('');
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !loading) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      console.log(`userData:`, userData);

      if (userData) setOrganizationName(organization?.name || 'Organization not found');

      if (userData && userData.superUser === true) {
        setUserRole(Roles.Root);

      }
      else if (userData && userData.currentOrganization) {
        setUserRole(userData.role);
        // setUserRole(Roles.Editor);
      } else {
        setOrganizationName('N/A');
        setUserRole(Roles.Reader);
      }
    };

    fetchData();
  }, [user, userData, loading, navigate]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return null;
  }

  return (
    <Container>
      <TopBar organization={organizationName} user={user} handleLogout={handleLogout} />
      <Grid container sx={{ mt: '64px' }}>
        <Grid item xs={2}>
          <Sidebar
            handleNavigation={handleNavigation}
            AccessList={AccessList}
            currentOrgRole={userRole}
            userData={userData}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MainLayout;
