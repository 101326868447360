import React from 'react';
import { Container, Typography } from '@mui/material';
import AddOrganization from '../Components/AddOrganization' 
import ListOrganizations from '../Components/ListOrganizations';
import Divider from '@mui/material/Divider';

function Organizations() {
  return(     
    <Container>
    <Typography variant="h4" component="h1">List Organizations</Typography>
    <Divider />
    <ListOrganizations collectionName="organizations" />
    <Divider />
    <AddOrganization />
  </Container>
  );
}

export default Organizations;