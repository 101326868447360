// modelToServiceMap.js
const ModelToServiceMap = {
  'gpt-4o': 'openai',
  'gpt-4': 'openai',
  'gpt-4-1106-preview': 'openai',
  'gpt-3.5-turbo': 'openai',
  'claude-3-5-sonnet-20240620': 'anthropic',
  'claude-3-5-sonnet-20241022': 'anthropic',
  'claude-3-7-sonnet-20250219': 'anthropic',
  'arn:aws:bedrock:us-east-1:862824515597:inference-profile/us.anthropic.claude-3-5-sonnet-20240620-v1:0': 'amazon-bedrock',
  'arn:aws:bedrock:us-east-1:862824515597:inference-profile/us.anthropic.claude-3-5-sonnet-20241022-v2:0': 'amazon-bedrock',
  'arn:aws:bedrock:us-east-1:862824515597:inference-profile/us.anthropic.claude-3-7-sonnet-20250219-v1:0': 'amazon-bedrock'
};

export default ModelToServiceMap;
