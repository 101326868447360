import React from 'react';
import { AppBar, Toolbar, Typography, Button, Grid } from '@mui/material';

export default function TopBar({ organization, user, handleLogout }) {
  return (
    <AppBar>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography variant="h6" component="div">
              AI Assistant v3
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" component="div">
              {organization}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" component="div">
              {user?.email}
            </Typography>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
