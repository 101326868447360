import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Select, MenuItem, Button, FormControl, Typography, Container } from '@mui/material';
import { removeOrganizationMember, resetUserPassword, getOrganization, updateOrganizationMember, getUser } from '../../Utilities/apiConnector';
import { ConfigContext } from '../../ConfigProvider';

function User() {
  const { userId, orgId } = useParams();
  const [user, setUser] = useState('');
  const [role, setRole] = useState('');
  const [org, setOrg] = useState(null);
  const { Roles, userData } = useContext(ConfigContext);

  useEffect(() => {
    if (Roles) {
      setRole(Roles.Reader);
    }
  }, [Roles]);

  useEffect(() => {
    const fetchUser = async () => {
      // Using the API connector to get user details
      const fetchedUser = await getUser(userId);
      setUser(fetchedUser);
    };
    fetchUser();
  }, [userId]);

  useEffect(() => {
    const fetchOrganization = async () => {
      // Prefer userData.currentOrganization if available; fallback to URL param
      const organizationId = userData && userData.currentOrganization ? userData.currentOrganization : orgId;
      const organizationResponse = await getOrganization(organizationId);
      // Assuming the response data is in .data
      setOrg(organizationResponse.data);
    };
    fetchOrganization();
  }, [orgId, userData]);

  const handleRoleChange = (event) => {
    event.preventDefault();
    setRole(event.target.value);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const response = await resetUserPassword(orgId, userId);
    console.log(response);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Update organization member role using the API connector
      const response = await updateOrganizationMember(orgId, userId, { role });
      console.log('Member updated successfully:', response);
    } catch (error) {
      console.error('Error updating member:', error);
    }
  };

  const handleRemoveFromOrganization = async (event) => {
    event.preventDefault();
    await removeOrganizationMember(orgId, userId);
  };

  return (
    <Container>
      <Typography variant="h5" component="h2">
        User {user?.displayName}, {user?.email}
      </Typography>
      <Typography variant="h5" component="h2">
        {org?.name}
      </Typography>
  
      {/* Display complete user info as formatted JSON */}
      <pre style={{ background: '#f5f5f5', padding: '1rem', borderRadius: '4px' }}>
        {JSON.stringify(user.data, null, 2)}
      </pre>
  
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Select value={role} onChange={handleRoleChange}>
            {Roles &&
              Object.keys(Roles).map((roleName) => (
                <MenuItem key={roleName} value={Roles[roleName]}>
                  {roleName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
        <Button type="button" variant="contained" color="secondary" onClick={handleRemoveFromOrganization}>
          Remove From Organization
        </Button>
        <Button type="button" variant="outlined" color="primary" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </form>
    </Container>
  );
}

export default User;
