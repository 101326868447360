import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { UserContext } from '../UserProvider';
import { getOrganization, acceptOrganizationInvitation, updateUserCurrentOrganization } from '../Utilities/apiConnector';

export default function OrganizationSelector() {
  const { userData } = useContext(UserContext);
  const [orgData, setOrgData] = useState([]);
  const [currentOrg, setCurrentOrg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrgData = async () => {
      const data = await Promise.all(
        userData.organizations.map(async (org) => {
          const response = await getOrganization(org.id);
          const organization = response.data;
          const orgDetails = {
            id: organization.id,
            name: organization.name,
            state: org.state, // using state instead of status
          };
          if (org.id === userData.currentOrganization) {
            setCurrentOrg(orgDetails);
          }
          return orgDetails;
        })
      );
      setOrgData(data);
      setIsLoading(false);
    };

    if (userData && userData.organizations) {
      fetchOrgData();
    } else {
      setIsLoading(false);
    }
  }, [userData]);

  const handleSelect = (org) => {
    try {
      updateUserCurrentOrganization(userData.id, org.id);
      setCurrentOrg(org);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleAcceptInvitation = async (org) => {
    try {
      await acceptOrganizationInvitation(org.id, userData.id);
      // Optionally update local state to reflect the change
      const updatedOrgData = orgData.map(o => {
        if (o.id === org.id) {
          return { ...o, state: 'accepted' };
        }
        return o;
      });
      setOrgData(updatedOrgData);
    } catch (error) {
      console.error("Error accepting invitation: ", error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!orgData.length) {
    return <Typography>No organizations available</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Organization Name</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Select</TableCell>
            <TableCell>Invitation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orgData.map((org) => (
            <TableRow key={org.id}>
              <TableCell>{org.name}</TableCell>
              <TableCell>{org.state}</TableCell>
              <TableCell>
                <Button variant="contained" onClick={() => handleSelect(org)}>
                  Select
                </Button>
              </TableCell>
              <TableCell>
                {org.state === 'pending' && (
                  <Button variant="contained" color="primary" onClick={() => handleAcceptInvitation(org)}>
                    Accept
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
