import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField 
} from '@mui/material';
import { 
  deleteNamespace, 
  deleteVectors, 
  listOrganizations, 
  getOrganization 
} from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

function Settings() {
  const { userData } = useContext(UserContext);
  const [namespace, setNamespace] = useState('');
  const [ownerid, setOwnerid] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const resp = await listOrganizations();
        if (resp && resp.data) {
          setOrganizations(resp.data);
        }
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };
    fetchOrganizations();
  }, []);

  const handleDeleteNamespace = async (e) => {
    e.preventDefault();
    try {
      const resp = await deleteNamespace("pinecone", namespace);
      console.log('Namespace pinecone deletion response:', resp);
      const resp2 = await deleteNamespace("immersion", namespace);
      console.log('Namespace immersion deletion response:', resp2);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteVectors = async (e) => {
    e.preventDefault();
    try {
      const resp = await deleteVectors(ownerid, 'pinecone', 'dev');
      console.log('Vectors deletion response:', resp);
      const resp2 = await deleteVectors(ownerid, 'immersion', 'dev');
      console.log('Vectors deletion response:', resp2);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetOrganization = async (orgId) => {
    try {
      const resp = await getOrganization(orgId);
      setSelectedOrg(resp.data);
    } catch (error) {
      console.error('Error fetching organization details:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Settings Page
      </Typography>
      
      <Paper style={{ padding: '16px', marginBottom: '24px' }}>
        <Typography variant="h6" gutterBottom>
          Namespace and Vectors Operations
        </Typography>
        <form onSubmit={handleDeleteNamespace}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="namespace"
            label="Namespace Name"
            name="namespace"
            value={namespace}
            onChange={(e) => setNamespace(e.target.value)}
          />
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            color="primary" 
            style={{ marginTop: '10px' }}
          >
            Nuke Namespace
          </Button>
        </form>
        <form onSubmit={handleDeleteVectors}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="ownerid"
            label="Owner ID"
            name="ownerid"
            value={ownerid}
            onChange={(e) => setOwnerid(e.target.value)}
          />
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            color="primary" 
            style={{ marginTop: '10px' }}
          >
            Nuke Vectors
          </Button>
        </form>
      </Paper>
      
      <Paper style={{ padding: '16px', marginBottom: '24px' }}>
        <Typography variant="h6" gutterBottom>
          Organizations
        </Typography>
        {organizations.length > 0 ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.map((org) => (
                  <TableRow key={org.id}>
                    <TableCell>{org.id}</TableCell>
                    <TableCell>{org.name}</TableCell>
                    <TableCell align="right">
                      <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={() => handleGetOrganization(org.id)}
                      >
                        Get Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No organizations found</Typography>
        )}
        {selectedOrg && (
          <Box mt={2}>
            <Typography variant="subtitle1">
              Selected Organization Details:
            </Typography>
            <pre>{JSON.stringify(selectedOrg, null, 2)}</pre>
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default Settings;
