// Chat.jsx
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import ChatBubble from '../Components/ChatBubble';
import ChatInput from '../Components/ChatInput';
import {
  runConversation,
  getConversationMessages,
  getConversation,
  addConversationMessages,
  clearConversationMessages,
  removeConversation,
  deleteConversationMessage, // using the API function for deletion
  getModule,
  getExpert,
  updateConversation  
} from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';
import FloatingWidget from '../Components/FloatingWidget';

export default function Chat() {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { chatId } = useParams();

  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [chatName, setChatName] = useState('');
  const [thinking, setThinking] = useState(false);
  const [floatingWidgetExperts, setFloatingWidgetExperts] = useState([]);
  const [expertIdInput, setExpertIdInput] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  
  // State for the informational popup
  const [infoPopup, setInfoPopup] = useState({ open: false, message: null });
  let lastParsedChunk;
  const messagesEndRef = useRef(null);

  useEffect(() => {
    loadConversation();
    loadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const getCurrentExpertName = () => {
    const currentExpert = floatingWidgetExperts.find(expert => expert.expertId === expertIdInput);
    return currentExpert ? currentExpert.expertName : 'Unknown Expert';
  };

  const showError = (message) => {
    setErrorMessages((prev) => [...prev, { id: Date.now(), message }]);
  };

  const handleCloseError = (id) => {
    setErrorMessages((prev) => prev.filter((msg) => msg.id !== id));
  };

  const loadConversation = async () => {
    try {
      const response = await getConversation(userData.currentOrganization, chatId);
      setConversation(response.data);
      if (response.data && response.data.name) {
        setChatName(response.data.name);
        // Fetch module details to populate experts for the FloatingWidget
        try {
          const moduleResponse = await getModule(userData.currentOrganization, response.data.module);
          if (moduleResponse?.data) {
            let experts = moduleResponse.data.experts || [];
            // Include summaryExpert if it exists
            if (moduleResponse.data.summaryExpert) {
              experts.push(moduleResponse.data.summaryExpert);
            }
            if (experts.length > 0) {
              const expertDetails = await Promise.all(
                experts.map(async (expert) => {
                  const expertResponse = await getExpert(userData.currentOrganization, expert);
                  const expertName = expert === moduleResponse.data.summaryExpert
                    ? `${expertResponse.data.name} (Summary)`
                    : expertResponse.data.name;
                  return { expertName, expertId: expertResponse.data.id };
                })
              );
              // Update FloatingWidget with the expert details and set the current expert
              setFloatingWidgetExperts(expertDetails);
              setExpertIdInput(expertDetails[0].expertId);
            }
          }
        } catch (moduleError) {
          showError("Failed to load module details: " + moduleError);
        }
      }
    } catch (error) {
      showError("Failed to load conversation: " + error);
    }
  };

  // Updated loadMessages to format each message
  const loadMessages = async () => {
    try {
      const response = await getConversationMessages(userData.currentOrganization, chatId);
      console.log(response);
      const formattedMessages = response.data.map((message) => {
        let summaryInfo = null;
        if (message.log && message.log.dataUsed && message.log.dataUsed.length) {
          try {
            // Parsing each JSON string in dataUsed into an array of objects.
            summaryInfo = message.log.dataUsed.map((item) => JSON.parse(item));
          } catch (error) {
            console.error("Error parsing log.dataUsed:", error);
          }
        }
        return {
          ...message,
          summaryInfo,
        };
      });
      setMessages(formattedMessages);
    } catch (error) {
      showError("Failed to load messages: " + error);
    }
  };

  const handleUpdateConversation = async () => {
    if (!conversation) return;
    try {
      // Create a new conversation object with only the fields required by the API.
      const updatedConv = {
        expert: conversation.expert,
        owner: conversation.owner,
        module: conversation.module,
        name: chatName, // Updated name from the TextField
        status: conversation.status,
      };
      const response = await updateConversation(userData.currentOrganization, conversation.id, updatedConv);
      if (response && response.data) {
        setConversation(response.data);
      }
    } catch (error) {
      showError("Failed to update conversation: " + error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleClear = async () => {
    if (!conversation) return;
    try {
      await clearConversationMessages(userData.currentOrganization, chatId);
      await loadMessages();
    } catch (error) {
      showError("Failed to clear conversation: " + error);
    }
  };

  const handleDelete = async () => {
    if (!conversation) return;
    try {
      await removeConversation(userData.currentOrganization, chatId);
      navigate('/chats');
    } catch (error) {
      showError("Failed to delete conversation: " + error);
    }
  };

  // Delete a single message using the API function
  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteConversationMessage(userData.currentOrganization, chatId, messageId);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== messageId)
      );
    } catch (error) {
      showError("Failed to delete message: " + error);
    }
  };

  // Open info popup for a message. It now shows both the text and any summary info.
  const handleInfoMessage = (msg) => {
    setInfoPopup({ open: true, message: msg });
  };

  const handleCloseInfoPopup = () => {
    setInfoPopup({ open: false, message: null });
  };

  const sendMessage = async (message) => {
    if (!conversation) {
      showError("Conversation not loaded yet.");
      return;
    }
    setThinking(true);
  
    // Create temporary unique IDs for the messages.
    const tempUserMessageId = Date.now();
    const tempBotMessageId = Date.now() + '_bot';
  
    // Add the user message and a placeholder for the bot message.
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: tempUserMessageId, message, isUser: true, isLoading: false }
    ]);
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: tempBotMessageId, message: '', isUser: false, isLoading: true, summaryInfo: null }
    ]);
  
    try {
      let fullText = '';
  
      await runConversation(
        userData.currentOrganization,
        chatId,
        { message, ...(expertIdInput && { expertid: expertIdInput }) },
        // Instead of handling each chunk separately, we accumulate them.
        (chunk) => {
          fullText += chunk;
          // Optionally update the UI as the text is accumulating.
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === tempBotMessageId ? { ...msg, message: fullText } : msg
            )
          );
        },
        async (error) => {
          if (error) {
            showError("Streaming error: " + error);
            setMessages((prevMessages) =>
              prevMessages.filter((msg) => msg.id !== tempBotMessageId)
            );
            setThinking(false);
            return;
          }
  
          // Now process the accumulated fullText.
          let displayText = fullText;
          let summaryInfo = null;
          // Look for the summary JSON starting at the end of the text.
          const jsonStartIndex = fullText.indexOf('{"type":"summary"');
          if (jsonStartIndex !== -1) {
            displayText = fullText.substring(0, jsonStartIndex).trim();
            const jsonPart = fullText.substring(jsonStartIndex);
            try {
              const parsedJson = JSON.parse(jsonPart);
              if (parsedJson.type === 'summary') {
                summaryInfo = parsedJson.data;
              }
            } catch (e) {
              console.error("Error parsing summary JSON:", e);
            }
          }
  
          // Update the bot message: display only the text portion and attach summaryInfo.
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === tempBotMessageId
                ? { ...msg, message: summaryInfo?.log?.completion || displayText, summaryInfo, isLoading: false }
                : msg
            )
          );
          setThinking(false);
  
          // Save both messages to the conversation history.
          try {
            // Save the user message.
            const userResponse = await addConversationMessages(
              userData.currentOrganization,
              chatId,
              {
                conversation: chatId,
                owner: userData.id,
                isUser: true,
                message,
                //createdAt :  Date.now()
              }
            );

            // Prepare the summary info with expert details
            let enhancedSummaryInfo = summaryInfo || {};
            
            // Make sure we have the correct structure
            if (!enhancedSummaryInfo.log) {
              enhancedSummaryInfo.log = {};
            }
            
            // Add expert information to the summary info
            enhancedSummaryInfo.expertInfo = {
              expertId: expertIdInput || '',
              expertName: getCurrentExpertName()
            };

            // Save the bot message.
            const botResponse = await addConversationMessages(
              userData.currentOrganization,
              chatId,
              {
                conversation: chatId,
                owner: userData.id,
                isUser: false,
                message: summaryInfo?.log?.completion || displayText,
                //createdAt :  Date.now(), 
                log: {
                  dataUsed: [enhancedSummaryInfo  ? JSON.stringify(enhancedSummaryInfo ) : ''] // Attaching the summary info from the JSON.
                }
              }
            );
  
            // Replace temporary IDs with server-assigned IDs.
            setMessages((prevMessages) =>
              prevMessages.map((msg) => {
                if (msg.id === tempUserMessageId) {
                  return { ...msg, id: userResponse.id };
                }
                if (msg.id === tempBotMessageId) {
                  return { ...msg, id: botResponse.id };
                }
                return msg;
              })
            );
          } catch (addError) {
            showError("Error adding conversation message to the server: " + addError);
          }
        }
      );
    } catch (error) {
      showError("Error sending message: " + error);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== tempBotMessageId)
      );
      setThinking(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={3} style={{ paddingTop: '20px' }}>
        <Grid item xs={12}>
          <TextField
            label="Chat Name"
            fullWidth
            value={chatName}
            onChange={(e) => setChatName(e.target.value)}
            onBlur={handleUpdateConversation}
          />
          <Box sx={{ mt: 1 }}>
            <Button variant="outlined" onClick={handleClear} sx={{ mr: 1 }}>
              Clear
            </Button>
            <Button variant="outlined" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ maxHeight: '60vh', overflowY: 'auto', marginBottom: '16px' }}>
            {messages.map((msg) => (
              <ChatBubble
                key={msg.id}
                message={msg.message}
                isUser={msg.isUser}
                onDelete={() => handleDeleteMessage(msg.id)}
                onInfo={() => handleInfoMessage(msg)}
              />
            ))}
            <div ref={messagesEndRef} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <ChatInput sendMessage={sendMessage} loading={thinking} />
        </Grid>
      </Grid>

      <FloatingWidget 
        setExpertIdInput={setExpertIdInput} 
        experts={floatingWidgetExperts}
      />

      {errorMessages.map(({ id, message }) => (
        <Snackbar
          key={id}
          open
          autoHideDuration={30000}
          onClose={() => handleCloseError(id)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={() => handleCloseError(id)} severity="error">
            {message}
          </Alert>
        </Snackbar>
      ))}

      {/* Informational Popup Dialog */}
      <Dialog open={infoPopup.open} onClose={handleCloseInfoPopup}>
        <DialogTitle>Message Information</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            <strong>Message:</strong> {infoPopup.message?.message}
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            <strong>Usage:</strong>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {infoPopup.message?.summaryInfo 
                ? JSON.stringify(infoPopup.message?.summaryInfo, null, 2).replace(/\\n/g, "\n")
                : 'N/A'}
            </pre>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoPopup}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
