import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TextField, Container, Typography, Paper, Alert } from '@mui/material';
import axios from 'axios';

const DEV = false;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Activation() {
  const landingPageUrl = '/';

  const query = useQuery();
  const navigate = useNavigate();

  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setToken(query.get('token'));
  }, [query]);

  const isValidPassword = (pass) => /^[a-zA-Z0-9 ._\-]+$/.test(pass) && pass.length >= 8;

  const arePasswordsMatching = password === confirmPassword;

  const handleActivate = async (event) => {
    event.preventDefault();
    console.log(token, password);

    try {
      const url = DEV ? 'http://localhost:5001/dev-immersion-ai-assistant/europe-west1/v3/v3/user/auth/activate' : 'https://dev.api.aibs.immersionlabs.io/v3/user/auth/activate';
      const resp = await axios.post(url, { password }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } });
      if (resp.status === 200) { 
        navigate(landingPageUrl); 
      }
      else throw new Error('User join failed');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5" gutterBottom>[DEV] Join AI Assistant App!</Typography>
        <form onSubmit={handleActivate}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Set Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={password !== "" && !isValidPassword(password)}
            helperText={
              password !== "" && !isValidPassword(password)
                ? "Password must be at least 8 characters and contain only letters, numbers and . _ - characters"
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Retype Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!arePasswordsMatching && confirmPassword !== ""}
            helperText={!arePasswordsMatching && confirmPassword !== "" ? "Passwords do not match" : ""}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
            disabled={
              password !== confirmPassword ||
              !isValidPassword(password)
            }
          >
            Join
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default Activation;