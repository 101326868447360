// ========== ExpertConfig.jsx ==========
import { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Slider,
  TextField,
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch
} from '@mui/material';
import ModelToServiceMap from '../Utilities/ModelToServiceMap';

export default function ExpertConfig({ data, onUpdate, onReload }) {
  // Provide defaults for all fields to avoid undefined
  const [config, setConfig] = useState({
    name: '',
    techName: '',
    vdb: 'pinecone',
    config: {
      model: 'gpt-4o',
      temp: 0.4,
      vdbTopK: 5,
      vdbScoreTreshold: 0,
      useHistory: false,
      prompt: {
        context: {
          role: 'user',
          enclosure: {
            start: '',
            end: ''
          }
        },
        finetunes: {
          enclosure: {
            start: '',
            end: ''
          }
        },
        history: {
          enclosure: {
            start: '',
            end: ''
          }
        },
        message: {
          enclosure: {
            start: '',
            end: ''
          }
        }
      },
      closingTag: '',
      commonDivider: ''
    }
  });

  useEffect(() => {
    if (data) {
      setConfig((prev) => ({
        ...prev,
        techName: data.techName ?? prev.techName,
        name: data.name ?? prev.name,
        vdb: data.vdb ?? prev.vdb,
        config: {
          ...prev.config,
          ...data.config,
          temp: data.config?.temp ?? prev.config.temp,
          vdbTopK: data.config?.vdbTopK ?? prev.config.vdbTopK,
          vdbScoreTreshold: data.config?.vdbScoreTreshold ?? prev.config.vdbScoreTreshold,
          useHistory: data.config?.useHistory ?? prev.config.useHistory,
          prompt: {
            ...prev.config.prompt,
            ...data.config?.prompt,
            context: {
              ...prev.config.prompt.context,
              ...data.config?.prompt?.context,
              enclosure: {
                ...prev.config.prompt.context.enclosure,
                ...(data.config?.prompt?.context?.enclosure ?? {})
              }
            },
            finetunes: {
              ...prev.config.prompt.finetunes,
              ...data.config?.prompt?.finetunes,
              enclosure: {
                ...prev.config.prompt.finetunes?.enclosure,
                ...(data.config?.prompt?.finetunes?.enclosure ?? {})
              }
            },
            history: {
              ...prev.config.prompt.history,
              ...data.config?.prompt?.history,
              enclosure: {
                ...prev.config.prompt.history?.enclosure,
                ...(data.config?.prompt?.history?.enclosure ?? {})
              }
            },
            message: {
              ...prev.config.prompt.message,
              ...data.config?.prompt?.message,
              enclosure: {
                ...prev.config.prompt.message?.enclosure,
                ...(data.config?.prompt?.message?.enclosure ?? {})
              }
            }
          },
          closingTag: data.config?.closingTag ?? prev.config.closingTag,
          commonDivider: data.config?.commonDivider ?? prev.config.commonDivider
        }
      }));
    }
  }, [data]);

  // Compute the maximum temperature based on the AI provider.
  // If the provider is "anthropic", max is 1; otherwise (or if undefined), max is 2.
  const maxTemp = (config.config.aiprovider || 'openai') === 'anthropic' ? 1 : 2;

  const handleSave = () => onUpdate(config);

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        {/* Basic Config */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            value={config.name}
            onChange={(e) => setConfig({ ...config, name: e.target.value })}
          />
          <TextField
            fullWidth
            label="Tech Name"
            value={config.techName}
            onChange={(e) => setConfig({ ...config, techName: e.target.value })}
          />
        </Grid>

        {/* Vector Database Select */}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="vdb-select-label">Vector Database</InputLabel>
            <Select
              labelId="vdb-select-label"
              id="vdb-select"
              label="Vector Database"
              value={config.vdb ?? ''}
              onChange={(e) => setConfig({ ...config, vdb: e.target.value })}
            >
              <MenuItem value="pinecone">Pinecone</MenuItem>
              <MenuItem value="immersion">Immersion</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Model Select */}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="model-select-label">Model</InputLabel>
            <Select
              labelId="model-select-label"
              id="model-select"
              label="Model"
              value={config.config?.model ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    model: e.target.value,
                    // Map the model to AI provider
                    aiprovider: ModelToServiceMap[e.target.value]
                  }
                }))
              }
            >
              {Object.keys(ModelToServiceMap).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> 

        {/* Model Parameters */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Model Parameters</Typography>
          <Box sx={{ px: 2 }}>
            <Typography>Temperature: {config.config.temp}</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9}>
                <Slider
                  value={config.config.temp}
                  onChange={(e, val) =>
                    setConfig((prev) => ({
                      ...prev,
                      config: { ...prev.config, temp: val }
                    }))
                  }
                  step={0.01}
                  min={0}
                  max={maxTemp}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={config.config.temp}
                  onChange={(e) => {
                    const newVal = Number(e.target.value);
                    if (!isNaN(newVal)) {
                      // Ensure value stays within bounds.
                      const clampedVal = Math.min(Math.max(newVal, 0), maxTemp);
                      setConfig((prev) => ({
                        ...prev,
                        config: { ...prev.config, temp: clampedVal }
                      }));
                    }
                  }}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: maxTemp,
                    type: 'number'
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Typography>VDB Top K: {config.config.vdbTopK}</Typography>
            <Slider
              value={config.config.vdbTopK}
              onChange={(e, val) =>
                setConfig((prev) => ({
                  ...prev,
                  config: { ...prev.config, vdbTopK: val }
                }))
              }
              step={1}
              min={1}
              max={20}
            />

            <Typography>VDB Score Threshold: {config.config.vdbScoreTreshold}</Typography>
            <Slider
              value={config.config.vdbScoreTreshold}
              onChange={(e, val) =>
                setConfig((prev) => ({
                  ...prev,
                  config: { ...prev.config, vdbScoreTreshold: val }
                }))
              }
              step={0.01}
              min={0}
              max={1}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={config.config.useHistory}
                  onChange={(e) =>
                    setConfig((prev) => ({
                      ...prev,
                      config: {
                        ...prev.config,
                        useHistory: e.target.checked
                      }
                    }))
                  }
                />
              }
              label="Use History"
            />
          </Box>
        </Grid>

        {/* Prompt Configuration */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Prompt Configuration</Typography>
          
          {/* Context Section */}
          <Box sx={{ px: 2, mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Context Settings</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="prompt-role-label">Role</InputLabel>
              <Select
                labelId="prompt-role-label"
                id="prompt-role-select"
                label="Role"
                value={config.config?.prompt?.context?.role ?? 'user'}
                onChange={(e) =>
                  setConfig((prev) => ({
                    ...prev,
                    config: {
                      ...prev.config,
                      prompt: {
                        ...prev.config.prompt,
                        context: {
                          ...prev.config.prompt.context,
                          role: e.target.value
                        }
                      }
                    }
                  }))
                }
              >
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="system">System</MenuItem>
                <MenuItem value="assistant">Assistant</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="subtitle2" sx={{ mb: 1 }}> </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>VDB Settings</Typography>
            <TextField
              fullWidth
              label="Context Start Enclosure"
              value={config.config?.prompt?.context?.enclosure?.start ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      context: {
                        ...prev.config.prompt.context,
                        enclosure: {
                          ...prev.config.prompt.context.enclosure,
                          start: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="Context End Enclosure"
              value={config.config?.prompt?.context?.enclosure?.end ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      context: {
                        ...prev.config.prompt.context,
                        enclosure: {
                          ...prev.config.prompt.context.enclosure,
                          end: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />
          </Box>

          {/* Finetunes Section */}
          <Box sx={{ px: 2, mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Finetunes Settings</Typography>
            <TextField
              fullWidth
              label="Finetunes Start Enclosure"
              value={config.config?.prompt?.finetunes?.enclosure?.start ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      finetunes: {
                        ...prev.config.prompt.finetunes,
                        enclosure: {
                          ...prev.config.prompt.finetunes?.enclosure,
                          start: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="Finetunes End Enclosure"
              value={config.config?.prompt?.finetunes?.enclosure?.end ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      finetunes: {
                        ...prev.config.prompt.finetunes,
                        enclosure: {
                          ...prev.config.prompt.finetunes?.enclosure,
                          end: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />
          </Box>

          {/* History Section */}
          <Box sx={{ px: 2, mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>History Settings</Typography>
            <TextField
              fullWidth
              label="History Start Enclosure"
              value={config.config?.prompt?.history?.enclosure?.start ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      history: {
                        ...prev.config.prompt.history,
                        enclosure: {
                          ...prev.config.prompt.history?.enclosure,
                          start: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="History End Enclosure"
              value={config.config?.prompt?.history?.enclosure?.end ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      history: {
                        ...prev.config.prompt.history,
                        enclosure: {
                          ...prev.config.prompt.history?.enclosure,
                          end: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />
          </Box>

          {/* Message Section */}
          <Box sx={{ px: 2, mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Message Settings</Typography>
            <TextField
              fullWidth
              label="Message Start Enclosure"
              value={config.config?.prompt?.message?.enclosure?.start ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      message: {
                        ...prev.config.prompt.message,
                        enclosure: {
                          ...prev.config.prompt.message?.enclosure,
                          start: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="Message End Enclosure"
              value={config.config?.prompt?.message?.enclosure?.end ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    prompt: {
                      ...prev.config.prompt,
                      message: {
                        ...prev.config.prompt.message,
                        enclosure: {
                          ...prev.config.prompt.message?.enclosure,
                          end: e.target.value
                        }
                      }
                    }
                  }
                }))
              }
              sx={{ mb: 2 }}
            />
          </Box>

          {/* Common Settings */}
          <Box sx={{ px: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Common Settings</Typography>
            <TextField
              fullWidth
              label="Closing Tag"
              value={config.config?.closingTag ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    closingTag: e.target.value
                  }
                }))
              }
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label="Common Divider"
              value={config.config?.commonDivider ?? ''}
              onChange={(e) =>
                setConfig((prev) => ({
                  ...prev,
                  config: {
                    ...prev.config,
                    commonDivider: e.target.value
                  }
                }))
              }
            />
          </Box>
        </Grid>

        {/* Actions */}
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSave} sx={{ mr: 1 }}>
            Save
          </Button>
          <Button variant="outlined" onClick={onReload}>
            Reload
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}