import React from 'react';
import { createRoot } from 'react-dom/client';
import UserProvider from './UserProvider';
import ConfigProvider from './ConfigProvider';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ConfigProvider>
      <UserProvider>
        <ThemeProvider theme={darkTheme}>
          <App />
        </ThemeProvider>
      </UserProvider>
    </ConfigProvider>
  </React.StrictMode>
);
