import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ErrorDialog = ({ config }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (config) { 
            setOpen(true); 
        } else { 
            setOpen(false); 
        }
    }, [config]);

    const handleClose = () => {
        if (config && config.handleClose) {
            config.handleClose();
        }
        setOpen(false);
    };

    const response = config?.error?.response;
    const header = response?.status ? response.status : "Error";
    const message = response?.data?.message || response?.data?.error || "Unknown Error Occurred";

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;