// ChatBubble.jsx
import * as React from 'react';
import { Card, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { grey, blue } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

const UserMessage = styled(Card)(({ theme }) => ({
  margin: '10px',
  padding: '10px',
  backgroundColor: blue[700],
  color: theme.palette.primary.contrastText,
  alignSelf: 'flex-end',
}));

const OtherMessage = styled(Card)(({ theme }) => ({
  margin: '10px',
  padding: '10px',
  backgroundColor: grey[300],
  alignSelf: 'flex-start',
}));

function ChatBubble({ message, isUser, onDelete, onInfo }) {
  const MessageCard = isUser ? UserMessage : OtherMessage;
  return (
    <MessageCard>
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
        {message}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {onInfo && (
          <IconButton color="info" onClick={onInfo}>
            <InfoIcon />
          </IconButton>
        )}
        {onDelete && (
          <IconButton color="error" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </MessageCard>
  );
}

export default ChatBubble;
