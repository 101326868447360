import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, onSnapshot } from '@firebase/firestore';
import { auth, db } from './firebase';
import { setUserApiKey, setDefaultHeaders, getOrganization, getUser } from './Utilities/apiConnector';

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const token = await authUser.getIdToken(true);
        setDefaultHeaders(token);

        const userDocRef = doc(db, 'users', authUser.uid);
        const unsubscribeFromSnapshot = onSnapshot(userDocRef, async (snapshot) => {
          const userData = (await getUser(authUser.uid)).data;
          userData.id = snapshot.id;

          setUser(authUser);
          setUserApiKey(userData.apiKey);

          if (userData.currentOrganization) { 
            const orgData = (await getOrganization(userData.currentOrganization)).data;
            if (orgData) {
              setOrganization(orgData);
              const member = orgData.members?.find(m => m.user === authUser.uid);
              userData.role = member?.role || null;
              if (userData?.superUser) { userData.role = "root"; }
            } else {
              setOrganization(null);
              userData.role = null;
            }
          }else{
            userData.role = "user";
          }

          setUserData(userData);
          setLoading(false);
        });

        return () => unsubscribeFromSnapshot();
      } else {
        setUser(null);
        setUserApiKey(null);
        setUserData(null);
        setOrganization(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, userData, organization, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
