import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  List,
  ListItem,
  Checkbox
} from '@mui/material';

// API calls
import { getFiles, getFinetunes } from '../Utilities/apiConnector';

export default function ResourceTable({
  header = 'Resources',
  onSelect,
  selected = [], // Array of string IDs
  // Remove filter from dependency unless you truly want to re-fetch every time filter changes
  filter = []
}) {
  const { orgId } = useParams();

  // State for all resources (fetched from the server)
  const [allResources, setAllResources] = useState([]);

  // State for the Add/Remove dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // Helper function to load resources from the server
  const refreshResources = async () => {
    if (!orgId) return;
    try {
      let response;
      if (header.toLowerCase() === 'files') {
        response = await getFiles(orgId);
      } else if (header.toLowerCase() === 'finetunes') {
        response = await getFinetunes(orgId);
      } else {
        response = { data: [] };
      }
      if (response && response.data) {
        const resources = response.data.map((r) => ({
          id: r.id,
          name: r.name || r.fileName || 'Untitled'
        }));
        setAllResources(resources);
      }
    } catch (error) {
      console.error('Error loading resources:', error);
    }
  };

  // Load resources on mount and when orgId or header changes
  useEffect(() => {
    refreshResources();
  }, [orgId, header]);

  // Dialog open/close
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {header}
      </Typography>

      {/* Currently selected items as Chips */}
      <Box sx={{ mb: 2 }}>
        {selected.map((id) => {
          const item = allResources.find((r) => r.id === id);
          if (!item) return null;
          return (
            <Chip
              key={id}
              label={`${item.name} (${id})`}
              sx={{ mr: 1, mb: 1 }}
            />
          );
        })}
      </Box>

      {/* Button to open Add/Remove dialog */}
      <Button variant="contained" onClick={handleOpenDialog}>
        Edit {header}
      </Button>

      {/* Dialog for choosing resources */}
      <AddResourceDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        resources={allResources}
        currentSelection={selected}
        title={`Select ${header}`}
        onSave={async (newSelection) => {
          // Notify parent about changes;
          // Assume onSelect returns a promise after successful update.
          await onSelect(newSelection);
          // Refresh the resource list to display changes immediately.
          refreshResources();
          handleCloseDialog();
        }}
      />
    </Box>
  );
}

/**
 * AddResourceDialog:
 * 1) Provides a searchable list of available resources (from props.resources).
 * 2) Allows multi-select.
 * 3) Calls onSave() with the final selection when user clicks “Save”.
 */
function AddResourceDialog({
  open,
  onClose,
  resources,
  currentSelection = [],
  title = 'Select Resources',
  onSave
}) {
  const [search, setSearch] = useState('');
  const [tempSelection, setTempSelection] = useState(currentSelection);

  // Reset local selection when dialog opens or if currentSelection changes from outside
  useEffect(() => {
    setTempSelection(currentSelection);
  }, [currentSelection, open]);

  // Filter resources based on search text
  const filteredResources = useMemo(() => {
    return resources.filter((r) =>
      r.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [resources, search]);

  // Toggle selection in local state
  const handleToggleResource = (id) => {
    setTempSelection((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // On “Save”, pass final selection up to parent
  const handleSave = () => {
    onSave(tempSelection);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>

        <List dense>
          {filteredResources.map((res) => {
            const isSelected = tempSelection.includes(res.id);
            return (
              <ListItem
                key={res.id}
                button
                onClick={() => handleToggleResource(res.id)}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Checkbox checked={isSelected} disableRipple />
                <Typography>
                  {res.name} ({res.id})
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
