import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions 
} from '@mui/material';
import { createServiceUser, addMemberToOrganization } from '../Utilities/apiConnector';

function AddServiceUserButton({ orgId  }) {
  const [open, setOpen] = useState(false);
  const [organizationId, setOrganizationId] = useState(orgId || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [createdUser, setCreatedUser] = useState(null);

  const handleOpen = () => {
    setOrganizationId(orgId || '');
    setError('');
    setSuccess('');
    setCreatedUser(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    setCreatedUser(null);
    try {
      // 1. Create a service user
      const serviceUserRes = await createServiceUser();
      if (!serviceUserRes || serviceUserRes.status !== 201) {
        throw new Error('Failed to create service user');
      }
      
      // 2. Add the service user to the organization
      const addRes = await addMemberToOrganization(organizationId, {
        userid: serviceUserRes.data.id,
        role: 'editor'
      });
      if (!addRes || addRes.status !== 201) {
        throw new Error('Failed to add service user to organization');
      }
      
      // Set the created user data to display as JSON
      setCreatedUser(serviceUserRes.data);
      setSuccess('Service user created and added successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto' }}>
      <Button 
        variant="contained" 
        onClick={handleOpen} 
        color="primary" 
        fullWidth 
        sx={{ marginBottom: 2 }}
      >
        Create &amp; Add Service User
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Service User</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Enter Organization ID
            </Typography>
            <TextField
              label="Organization ID"
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            {success && (
              <Typography color="primary" variant="body2">
                {success}
              </Typography>
            )}
            {createdUser && (
              <Box mt={2} p={1} bgcolor="#f5f5f5">
                <Typography variant="subtitle2">Created User Information:</Typography>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {JSON.stringify(createdUser, null, 2)}
                </pre>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Processing...' : 'Submit'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default AddServiceUserButton;