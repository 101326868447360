import React, { useRef, useState, useContext } from 'react';
import { Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { addStorageResource } from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

export default function FileUploader({ type, owner, onUploadSuccess }) {
  const fileInputRef = useRef(null);
  const [pending, setPending] = useState(false);
  const { user, userData } = useContext(UserContext);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    // Loop through all selected files and upload them sequentially
    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i]);
    }
    // Call the onUploadSuccess callback after all uploads are complete
    if (onUploadSuccess) {
      onUploadSuccess();
    }
  };

  const uploadFile = async (file) => {
    if (!user) return;
    console.log("userData:", userData);
    setPending(true);
    try {
      await addStorageResource(file, owner, type, userData.currentOrganization);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setPending(false);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {!pending ? (
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleUploadClick}>
            Upload
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
            style={{ display: 'none' }}
          />
        </Grid>
      ) : (
        <Grid item xs>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
