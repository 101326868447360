import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, ListItem, ListItemText, Switch, FormControlLabel, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CircularProgress from '@mui/material/CircularProgress';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { green, red, blue, grey } from '@mui/material/colors';
import { deleteVectors, getResource, deleteFile, processFile, getFiles, getFile } from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

function FileList({ onSelectFiles, category, id, refreshKey }) {
  const { user, userData } = useContext(UserContext);
  const { orgId } = useParams();
  const [files, setFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [useRaw, setUseRaw] = useState(true);
  const [filterText, setFilterText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, id, refreshKey]);

  // Polling mechanism for updating file statuses
  useEffect(() => {
    const pollInterval = setInterval(() => {
      // Only poll files that are not in a terminal state
      files.forEach(file => {
        if (file.status == 'pending') {
          getFile(userData.currentOrganization, file.id)
            .then(response => {
              const updatedStatus = response.data.status;
              // Update file in state if status has changed
              setFiles(prevFiles =>
                prevFiles.map(f =>
                  f.id === file.id ? { ...f, status: updatedStatus, fileURL: response.data.fileURL } : f
                )
              );
            })
            .catch(error => console.error('Polling error for file:', file.id, error));
        }
      });
    }, 5000);

    // Clean up on component unmount
    return () => clearInterval(pollInterval);
  }, [files, userData.currentOrganization]);

  const handleToggleChange = (event) => {
    setUseRaw(event.target.checked);
  };

  const columns = [
    {
      field: 'fileName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <ListItem>
          <ListItemText
            primary={params.value}
            secondary={`ID: ${params.row.id}`}
          />
        </ListItem>
      ),
    },
    {
      field: 'fileRef',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        params.value ? (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => { e.stopPropagation(); handleDownload(params.row); }}
            >
              Download
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginLeft: '10px' }}
              onClick={(e) => { e.stopPropagation(); handleEdit(params.row); }}
            >
              Edit
            </Button>
          </>
        ) : (
          <>File doesn't exist</>
        )
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params) => {
        switch (params.value) {
          case 'new': return <FiberNewIcon style={{ color: blue[500] }} />;
          case 'pending': return <CircularProgress size={20} />;
          case 'ready': return <CheckCircleIcon style={{ color: green[500] }} />;
          case 'aborted': return <DoNotDisturbAltIcon style={{ color: grey[500] }} />;
          case 'error': return <ErrorOutlineIcon style={{ color: red[500] }} />;
          default: return null;
        }
      }
    },
    {
      field: 'vectors',
      headerName: 'Vectors Count',
      flex: 0.5,
      renderCell: (params) => params.value?.length || "-"
    },
  ];

  const handleDownload = async (file) => {
    try {
      const response = await getFile(userData.currentOrganization, file.id);
      if (response.data.fileURL) {
        const res = await fetch(response.data.fileURL);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.data.fileName || 'download');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('File URL is missing');
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const handleEdit = (row) => {
    navigate(`/organizations/${orgId}/files/${row.id}`);
  };

  const loadFiles = async () => {
    if (!userData?.currentOrganization) {
      console.error('Undefined organization');
      return;
    }
    try {
      const response = await getFiles(userData.currentOrganization);
      setFiles(response.data);
    } catch (error) {
      console.error("Error loading files", error);
    }
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedFiles.length === 0) return;
    const ids = selectedFiles.map(file => file.id);
    try {
      await deleteFile(ids[0], userData.currentOrganization);
      setOpenDeleteDialog(false);
      loadFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleProcessFiles = async () => {
    const promises = selectedFiles.map(async (file) => {
      if (file.status === 'vectorized') {
        await deleteVectors(file.id, null, null);
      }
      return processFile(file, useRaw, userData.currentOrganization);
    });
    try {
      await Promise.all(promises);
      setSelectedFiles([]);
      loadFiles();
    } catch (error) {
      console.error("Error processing files:", error);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleSelectionChange = (newSelection) => {
    const selectableIds = newSelection.filter((id) =>
      files.find((file) => file.id === id && file.status !== 'pending')
    );
    const selectedFiles = files.filter((file) => selectableIds.includes(file.id));
    setSelectedFiles(selectedFiles);
    onSelectFiles(selectedFiles);
  };

  const filteredFiles = files.filter(file =>
    file.fileName.toLowerCase().includes(filterText.toLowerCase()) ||
    file.id.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <Paper>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" component="h1" sx={{ p: 2 }}>
            File List
          </Typography>
          {selectedFiles.length > 0 && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<RotateLeftIcon />}
                onClick={handleProcessFiles}
              >
                Process
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    checked={useRaw}
                    onChange={handleToggleChange}
                    name="useRaw"
                    color="primary"
                  />
                }
                label="No Compression"
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </>
          )}
        </Box>
        <Box sx={{ m: 2 }}>
          <TextField
            label="Filter Files"
            fullWidth
            margin="normal"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Box>
        <Box sx={{ m: 2 }}>
          <DataGrid
            rows={filteredFiles}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionChange}
            rowSelectionModel={selectedFiles.map(file => file.id)}
            getRowId={(row) => row.id}
            isRowSelectable={(params) => params.row.status !== 'pending'}
            disableSelectionOnClick
          />
        </Box>
        <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
          <DialogTitle>Delete File</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete these {selectedFiles.length} file(s)?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
}

export default FileList;
