import React, { useState, useContext } from 'react';
import FileUploader from '../Components/FileUploader';
import FileList from '../Components/FileList';
import { UserContext } from '../UserProvider';
import { TableContainer, Stack } from '@mui/material';

function Files() {
  const { userData } = useContext(UserContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // Use a refresh key to force FileList to reload files when it changes.
  const [fileUploadTrigger, setFileUploadTrigger] = useState(0);

  const handleFileSelection = (files) => {
    setSelectedFiles(files);
  };

  // Callback to refresh file list after an upload
  const refreshFileList = () => {
    setFileUploadTrigger((prev) => prev + 1);
  };

  return (
    <TableContainer>
      {userData && userData.currentOrganization && (
        <Stack>
          {/* Pass refreshFileList to FileUploader */}
          <FileUploader 
            owner={userData.currentOrganization} 
            type="files" 
            onUploadSuccess={refreshFileList} 
          />
          <FileList 
            onSelectFiles={handleFileSelection} 
            category="organizations" 
            id={userData.currentOrganization} 
            type="file" 
            collectionName="images" 
            refreshKey={fileUploadTrigger} 
          />
        </Stack>
      )}
    </TableContainer>
  );
}

export default Files;
