import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, Container, Grid, TextField, Button, CircularProgress, Typography, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon, ListItemText, Checkbox, Radio,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { getModule, updateModule, getExperts, setFirstExpert, listSchemas } from '../Utilities/apiConnector'; // Added listSchemas

export default function Module() {
  const { orgId, moduleId } = useParams();
  const [data, setData] = useState(null);
  const [editedData, setEditedData] = useState({
    name: '',
    experts: [],
    summaryExpert: '',
    techName: '',
    reportValidatorSchema: ''
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [expertsList, setExpertsList] = useState([]); 
  const [schemasList, setSchemasList] = useState([]);
  const [expertDialogOpen, setExpertDialogOpen] = useState(false);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [firstExpertDialogOpen, setFirstExpertDialogOpen] = useState(false);
  const [settingFirstExpert, setSettingFirstExpert] = useState(false);

  // State for filtering experts
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    loadModule();
  }, [orgId, moduleId]);

  useEffect(() => {
    loadExperts();
    loadSchemas();
  }, [orgId]);

  const loadModule = async () => {
    try {
      setLoading(true);
      const moduleData = await getModule(orgId, moduleId);
      setData(moduleData.data);
      setEditedData({
        name: moduleData.data.name || '',
        experts: moduleData.data.experts || [],
        summaryExpert: moduleData.data.summaryExpert || '',
        techName: moduleData.data.techName || '',
        reportValidatorSchema: moduleData.data.reportValidatorSchema || ''
      });
    } catch (error) {
      console.error("Error loading module:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadExperts = async () => {
    try {
      const experts = await getExperts(orgId);
      setExpertsList(experts.data);
    } catch (error) {
      console.error('Error loading experts:', error);
    }
  };

  const loadSchemas = async () => {
    try {
      const response = await listSchemas(orgId);
      
      // Check if response is in the format { data: [...] }
      const schemasData = response.data && Array.isArray(response.data) 
        ? response.data 
        : Array.isArray(response) 
          ? response 
          : [];
      
      setSchemasList(schemasData);
    } catch (error) {
      console.error("Error loading schemas:", error);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const updated = {
        name: editedData.name,
        experts: editedData.experts, 
        summaryExpert: editedData.summaryExpert,
        techName: editedData.techName,
        reportValidatorSchema: editedData.reportValidatorSchema
      };
      await updateModule(orgId, moduleId, updated);
      await loadModule();
    } catch (error) {
      console.error('Error updating module:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field) => (event) => {
    setEditedData(prev => ({ ...prev, [field]: event.target.value }));
  };

  const handleOpenExpertDialog = () => {
    setExpertDialogOpen(true);
  };

  const handleCloseExpertDialog = () => {
    setExpertDialogOpen(false);
  };

  const handleOpenSummaryDialog = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };
  
  const handleOpenFirstExpertDialog = () => {
    setFirstExpertDialogOpen(true);
  };

  const handleCloseFirstExpertDialog = () => {
    setFirstExpertDialogOpen(false);
  };

  const toggleExpertSelection = (id) => {
    setEditedData((prev) => {
      const exists = prev.experts.includes(id);
      if (exists) {
        return { ...prev, experts: prev.experts.filter(e => e !== id) };
      } else {
        return { ...prev, experts: [...prev.experts, id] };
      }
    });
  };

  const selectSummaryExpert = (id) => {
    setEditedData((prev) => ({ ...prev, summaryExpert: id }));
  };
  
  const handleSetFirstExpert = async (expertId) => {
    setSettingFirstExpert(true);
    try {
      await setFirstExpert(orgId, moduleId, expertId);
      // Reload the module to refresh data
      await loadModule();
      handleCloseFirstExpertDialog();
    } catch (error) {
      console.error('Error setting first expert:', error);
    } finally {
      setSettingFirstExpert(false);
    }
  };

  // Filter experts based on filterText (matches name or id)
  const filteredExperts = expertsList.filter(expert => 
    expert.name.toLowerCase().includes(filterText.toLowerCase()) ||
    expert.id.toLowerCase().includes(filterText.toLowerCase())
  );

  if (loading) {
    return (
      <Container sx={{ p: 2 }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container sx={{ p: 2 }}>
        <Typography variant="h6">Module not found</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ p: 2 }}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <TextField
            value={editedData.name}
            onChange={handleChange('name')}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{ 
              style: { fontSize: '2rem' } 
            }}
          />
          <TextField
            fullWidth
            label="Tech Name"
            onChange={handleChange('techName')}
            value={editedData.techName}
            margin="normal"
          />
          <Typography variant="body2">ID: {data.id}</Typography>
          <Typography variant="body2">Owner: {data.owner}</Typography>
        </Grid>
      </Grid>

      {saving ? (
        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40vh', height: '5vh', m: 5, p: 1 }}>
            <Typography variant="h6" component="span" sx={{ mr: 2 }}>
              Saving...
            </Typography>
            <CircularProgress />
          </Paper>
        </Grid>
      ) : (
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {/* Select Experts */}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleOpenExpertDialog} sx={{ mb: 2 }}>
                Select Experts
              </Button>
              <Typography variant="body2">Selected Experts: </Typography>
              <Typography variant="body1">
                {editedData.experts.length === 0
                  ? 'No experts selected.'
                  : editedData.experts.map(eId => {
                      const expert = expertsList.find(exp => exp.id === eId);
                      return expert ? expert.name + ` (${expert.id})` : eId;
                    }).join(', ')
                }
              </Typography>
            </Grid>

            {/* Select Summary Expert */}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleOpenSummaryDialog} sx={{ mb: 2 }}>
                Select Summary Expert
              </Button>
              <Typography variant="body2">Summary Expert: </Typography>
              <Typography variant="body1">
                {editedData.summaryExpert
                  ? `${expertsList.find(exp => exp.id === editedData.summaryExpert)?.name || 'Unknown'} (${editedData.summaryExpert})`
                  : 'No summary expert selected.'
                }
              </Typography>
            </Grid>

            {/* Report Validator Schema */}
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="schema-select-label">Report Validator Schema</InputLabel>
                <Select
                  labelId="schema-select-label"
                  value={editedData.reportValidatorSchema || ''}
                  label="Report Validator Schema"
                  onChange={handleChange('reportValidatorSchema')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {schemasList.map((schema) => (
                    <MenuItem key={schema.id} value={schema.id}>
                      {schema.name} | ID: {schema.id} | Type: {schema.type || 'N/A'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Set First Expert */}
            <Grid item xs={12}>
              <Button 
                variant="outlined" 
                onClick={handleOpenFirstExpertDialog} 
                sx={{ mb: 2 }}
                disabled={editedData.experts.length === 0}
              >
                Set First Expert
              </Button>
              <Typography variant="body2">
                Determines which expert appears first in the list for other systems.
              </Typography>
            </Grid>

            {/* Save Button */}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Dialog for Expert Selection */}
      <Dialog open={expertDialogOpen} onClose={handleCloseExpertDialog} fullWidth>
        <DialogTitle>Select Experts</DialogTitle>
        <DialogContent dividers>
          {/* Filter input */}
          <TextField
            label="Filter Experts"
            fullWidth
            margin="normal"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <List>
            {filteredExperts.map((expert) => {
              const selected = editedData.experts.includes(expert.id);
              return (
                <ListItem button key={expert.id} onClick={() => toggleExpertSelection(expert.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${expert.name} (${expert.id})`} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExpertDialog}>Done</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Summary Expert Selection */}
      <Dialog open={summaryDialogOpen} onClose={handleCloseSummaryDialog} fullWidth>
        <DialogTitle>Select Summary Expert</DialogTitle>
        <DialogContent dividers>
          {/* Filter input */}
          <TextField
            label="Filter Experts"
            fullWidth
            margin="normal"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <List>
            {/* Option to select NONE */}
            <ListItem button onClick={() => selectSummaryExpert('')}>
              <ListItemIcon>
                <Radio
                  edge="start"
                  checked={editedData.summaryExpert === ''}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary="None" />
            </ListItem>
            {filteredExperts.map((expert) => {
              const selected = editedData.summaryExpert === expert.id;
              return (
                <ListItem button key={expert.id} onClick={() => selectSummaryExpert(expert.id)}>
                  <ListItemIcon>
                    <Radio
                      edge="start"
                      checked={selected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${expert.name} (${expert.id})`} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSummaryDialog}>Done</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Setting First Expert */}
      <Dialog open={firstExpertDialogOpen} onClose={handleCloseFirstExpertDialog} fullWidth>
        <DialogTitle>Set First Expert</DialogTitle>
        <DialogContent dividers>
          {settingFirstExpert ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={3}>
              <CircularProgress />
              <Typography variant="body1" sx={{ ml: 2 }}>
                Setting first expert...
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Select which expert should appear first in the list for other systems:
              </Typography>
              {/* Filter input */}
              <TextField
                label="Filter Selected Experts"
                fullWidth
                margin="normal"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
              <List>
                {expertsList
                  .filter(expert => 
                    editedData.experts.includes(expert.id) &&
                    (expert.name.toLowerCase().includes(filterText.toLowerCase()) ||
                    expert.id.toLowerCase().includes(filterText.toLowerCase()))
                  )
                  .map((expert) => (
                    <ListItem button key={expert.id} onClick={() => handleSetFirstExpert(expert.id)}>
                      <ListItemText primary={`${expert.name} (${expert.id})`} />
                    </ListItem>
                  ))
                }
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFirstExpertDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}