import axios from 'axios';
import { auth } from '../firebase';
import { eventManager } from './eventManager';

// ========================================================================
// COMMON CONFIGURATION & CONSTANTS
// ========================================================================

const DEV_MODE = process.env.NODE_ENV === 'development';
const DEV = false; //<-- TODO TEMPRARY WORK ON LIVE BACKEND

const DEV_BASE = "http://127.0.0.1:5001/dev-immersion-ai-assistant/europe-west1/v3";
const PROD_BASE = "https://dev.api.aibs.immersionlabs.io";
const TARGET_BASE = DEV ? DEV_BASE : PROD_BASE;
const API_VERSION = "v3";

const ENDPOINT_CORE = `/core`;
const ENDPOINT_ORGANIZATIONS = `/organizations`;
const ENDPOINT_OPERATIONS = `/operations`;
const ENDPOINT_OPERATIONS_FILE = `/files`;
const ENDPOINT_OPERATIONS_TAG = `/operations/tag`;
const ENDPOINT_OPERATIONS_VECTORS = `/operations/vectors`;
const ENDPOINT_OPERATIONS_OBJECT = `/operations/object`;
const ENDPOINT_COMPLETIONS_CHATGPT = `/completions/chatgpt`;
const ENDPOINT_TTS_OPENAI = `/tts/openai`;
const ENDPOINT_STT_OPENAI = `/stt/openai`;
const ENDPOINT_OPERATIONS_API = `/operations/api`;
const ENDPOINT_CONVERSATIONS_API = `/conversations`;
const ENDPOINT_THREAD_API = `/threads`;
const ENDPOINT_USER = `/users`;
const ENDPOINT_MODULES = `/modules`;
const ENDPOINT_SCHEMAS = `/schemas`;

// ========================================================================
// AXIOS INSTANCE & INTERCEPTORS
// ========================================================================

const apiClient = axios.create({ baseURL: `${TARGET_BASE}/${API_VERSION}` });

apiClient.interceptors.request.use(
  async (config) => {
    return config;
  },
  (e) => {
    return Promise.reject(e);
  }
);

apiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const req = error.config;
    // Token refresh if 401 and not retried
    if (error.response.status === 401 && !req._retry) {
      req._retry = true;
      console.log("Refreshing token...");
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken(true);
        setDefaultHeaders(token);
        req.headers['Authorization'] = `Bearer ${token}`;
        return apiClient(req);
      }
    } else if (!error.response || error.response.status >= 400) {
      eventManager.publish('api-error', error);
    }
    return Promise.reject(error);
  }
);

let userJWTToken = null;
export const setUserApiKey = (key) => {
  userJWTToken = key;
};

export const setDefaultHeaders = (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    'API-Auth': userJWTToken
  };
  apiClient.defaults.headers.common = { ...apiClient.defaults.headers.common, ...headers };
};

// ========================================================================
// USER EDITING
// ========================================================================

export const addUserToOrganization = async (orgId, email, role) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/user`, { email, role }).catch(e => e);
export const removeUserFromOrganization = async (orgId, userId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/user/${userId}`).catch(e => e);
//export const resetUserPassword = async (orgId, userId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/user/${userId}/resetpassword`).catch(e => e);
export const createServiceUser = async () =>
  await apiClient.post(`/user`, { type: "service", systemRole: "user" }).catch(e => e);

// ========================================================================
// USER MANAGEMENT API
// ========================================================================

export const loginUser = async (email, password, scope) =>
  await apiClient.post(`/user/auth/login`, { email, password, scope }).catch(e => e); // "scope": "apiKey" always for now 
export const createUser = async (email) =>
  await apiClient.post(`/user`, { email }).catch(e => e);
export const getUser = async (userId) =>
  await apiClient.get(`/user/${userId}`).catch(e => e);
export const updateUser = async (userId, displayName) =>
  await apiClient.put(`/user/${userId}`, { displayName }).catch(e => e);
export const updateUserCurrentOrganization = async (userId, currentOrganization) =>
  await apiClient.put(`/user/${userId}`, { currentOrganization }).catch(e => e);
export const deleteUser = async (userId) =>
  await apiClient.delete(`/user/${userId}`).catch(e => e);
export const resetUserPassword = async (userId) =>
  await apiClient.get(`/user/${userId}/password`).catch(e => e);
export const updateUserPassword = async (userId, password) =>
  await apiClient.put(`/user/${userId}/password`, { password }).catch(e => e);
export const createAppPassword = async (userId, email, password) =>
  await apiClient.post(`/user/${userId}/apppass`, { email, password }).catch(e => e);
export const deleteAppPassword = async (userId) =>
  await apiClient.delete(`/user/${userId}/apppass`).catch(e => e);

// ========================================================================
// ORGANIZATION API
// ========================================================================

export const createOrganization = async (data) =>
  await apiClient.post('/organizations', data).catch(e => e);
export const listOrganizations = async () =>
  await apiClient.get('/organizations').catch(e => e);
export const getOrganization = async (id) =>
  await apiClient.get(`/organizations/${id}`).catch(e => e);
export const updateOrganization = async (data, id) =>
  await apiClient.put(`/organizations/${id}`, data).catch(e => e);
export const deleteOrganization = async (id) =>
  await apiClient.delete(`/organizations/${id}`).catch(e => e);
export const setSecret = async (id, secretType, data) =>
  await apiClient.post(`/organizations/${id}/secrets/${secretType}`, data).catch(e => e); // secretType: openai, anthropickey
export const getSecret = async (id, secretType) =>
  await apiClient.get(`/organizations/${id}/secrets/${secretType}`).catch(e => e); // secretType: openai, anthropickey
export const deleteSecret = async (id, secretType) =>
  await apiClient.delete(`/organizations/${id}/secrets/${secretType}`).catch(e => e); // secretType: openai, anthropickey
export const validateSecret = async (id, secretType, data) =>
  await apiClient.post(`/organizations/${id}/secrets/${secretType}/validate`, data).catch(e => e); // secretType: openai, anthropickey

// ========================================================================
// MEMBERS API
// ========================================================================

export const addMemberToOrganization = async (organizationId, data) =>
  await apiClient.post(`/organizations/${organizationId}/members`, data).catch(e => e);
export const listOrganizationMembers = async (organizationId) =>
  await apiClient.get(`/organizations/${organizationId}/members`).catch(e => e);
export const updateOrganizationMember = async (organizationId, memberId, data) =>
  await apiClient.put(`/organizations/${organizationId}/members/${memberId}`, data).catch(e => e);
export const removeOrganizationMember = async (organizationId, memberId) =>
  await apiClient.delete(`/organizations/${organizationId}/members/${memberId}`).catch(e => e);
export const acceptOrganizationInvitation = async (organizationId, memberId) =>
  await apiClient.get(`/organizations/${organizationId}/members/${memberId}/accept`).catch(e => e);

// ========================================================================
// VECTORIZATION
// ========================================================================

export const processFile = async (file, useRaw, orgId) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_OPERATIONS_FILE}/${file.id}/embed`, { useRaw: useRaw }).catch(e => e);
export const getFileVectorIDs = async (file, orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_OPERATIONS_FILE}/${file.id}/vectors`).catch(e => e);
export const processTag = async (tag, useRaw) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS_TAG}/vectorize`, { tag }).catch(e => e);
export const addVectors = async (owner, vdb, namespace) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS_VECTORS}`, { owner, vdb, namespace }).catch(e => e);
export const deleteVectors = async (ownerid, vdb, namespace) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS_VECTORS}/${ownerid}/${vdb}/${namespace}`).catch(e => e);
export const deleteFileData = async (fileId, vdb, namespace) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS_FILE}/${fileId}`).catch(e => e);
export const deleteNamespace = async (vdb, namespace) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS_VECTORS}/${vdb}/${namespace}`).catch(e => e);

// ========================================================================
// CONVERSATIONS
// ========================================================================

export const runConversation = async (
  orgId,
  id,
  data,
  onChunk,
  onStreamEndCallback
) => {
  try {
    const bearerToken = apiClient.defaults.headers.common['Authorization'];
    const url = `${apiClient.defaults.baseURL}${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/run`;

    // Standard POST request (no streaming)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(bearerToken ? { Authorization: bearerToken } : {}),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorDetails = await response.json().catch(() => null);
      console.error(`Request failed with status ${response.status}`, errorDetails);
      throw new Error(`Request failed with status ${response.status}: ${errorDetails?.message || 'No additional info'}`);
    }

    // Process streamed response text
    const responseText = await response.text();
    const lines = responseText.split('\n').map(line => line.trim());
    for (let line of lines) {
      if (!line) continue;
      let chunk = line.replace(/^data:\s?/, '');
      if (chunk.includes('"usage":') || chunk.includes('"log":')) {
        try {
          const summaryData = JSON.parse(chunk);
          // Sending back a JSON object that indicates it's a summary chunk.
          onChunk(JSON.stringify({ type: 'summary', data: summaryData }));
        } catch (e) {
          console.error('Failed to parse summary chunk', e);
        }
        continue;
      }
      onChunk(chunk);
    }
    if (onStreamEndCallback) {
      console.log('onStreamEndCallback');
      onStreamEndCallback();
    }
  } catch (error) {
    console.error('Error in runConversation:', error);
    if (onStreamEndCallback) {
      onStreamEndCallback(error);
    }
    throw error;
  }
};

export const getConversationMessages = async (orgId, id) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/messages`).catch(e => e);
export const clearConversationMessages = async (orgId, id) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/messages`).catch(e => e);
export const addConversationMessages = async (orgId, id, messagesPayload) => {
  try {
    const url = `${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/messages`;
    const response = await apiClient.post(url, messagesPayload);
    return response.data;
  } catch (error) {
    console.error("Error adding conversation message:", error);
    throw new Error("Failed to add conversation message");
  }
};



export const createThread = async (data) =>
  await apiClient.post(`${ENDPOINT_THREAD_API}`, data).catch(e => e);
export const getConversations = async (orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}`).catch(e => e);
export const getConversationsByModule = async (orgId, id) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/modules/${id}${ENDPOINT_CONVERSATIONS_API}`).catch(e => e);
export const getConversation = async (orgId, id) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}`).catch(e => e);
export const createConversation = async (orgId, conversationData) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}`, conversationData).catch(e => e);
export const removeConversation = async (orgId, id) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}`).catch(e => e);
export const updateConversation = async (orgId, conversationId, conversationData) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${conversationId}`,conversationData).catch(e => e);


// Additional Conversation Endpoints
export const changeConversationExpert = async (orgId, conversationId, expertid) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${conversationId}/expert`, { expertid }).catch(e => e);
export const getConversationStatus = async (orgId, conversationId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${conversationId}/status`).catch(e => e);
export const updateConversationStatus = async (orgId, conversationId, status) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${conversationId}/status`, { status }).catch(e => e);
export const getConversationMessage = async (orgId, conversationId, messageId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${conversationId}/messages/${messageId}`).catch(e => e);
export const deleteConversationMessage = async (orgId, conversationId, messageId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${conversationId}/messages/${messageId}`).catch(e => e);

// ========================================================================
// MODULES
// ========================================================================

export const listModules = async (orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}`).catch(e => e);
export const createModule = async (orgId, moduleData) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}`, moduleData).catch(e => e);
export const removeModule = async (orgId, moduleId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}`).catch(e => e);
export const getModule = async (orgId, moduleId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}`).catch(e => e);
export const updateModule = async (orgId, moduleId, data) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}`, data).catch(e => e);
export const setFirstExpert = async (orgId, moduleId, expertId) =>
  await apiClient.put(
    `${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}/setfirst`,
    { expertid: expertId }
  ).catch(e => e);
  
// ========================================================================
// SCHEMAS
// ========================================================================

export const listSchemas = async (orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_SCHEMAS}`).catch(e => e);

export const createSchema = async (orgId, schemaData) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_SCHEMAS}`, schemaData).catch(e => e);

export const removeSchema = async (orgId, schemaId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_SCHEMAS}/${schemaId}`).catch(e => e);

export const getSchema = async (orgId, schemaId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_SCHEMAS}/${schemaId}`).catch(e => e);

export const updateSchema = async (orgId, schemaId, data) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_SCHEMAS}/${schemaId}`, data).catch(e => e);

// ========================================================================
// OBJECTS
// ========================================================================

// export const deleteObjectData = async (id) => await apiClient.delete(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);
export const getObject = async (id) =>
  await apiClient.get(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);
export const deleteObject = async (id) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);

export const setTag = async (data) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS}/tag`, data).catch(e => e);
export const updateTag = async (id, data) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS}/tag/${id}`, data).catch(e => e);

// Core Resources
export const getCoreResource = async (type, id) =>
  await apiClient.get(`${ENDPOINT_CORE}/${type}/${id}`).catch(e => e);
export const setCoreResource = async (type, data) =>
  await apiClient.post(`${ENDPOINT_CORE}/${type}`, { data }).catch(e => e);
export const updateCoreResource = async (type, data, id) =>
  await apiClient.post(`${ENDPOINT_CORE}/${type}/${id}`, { data }).catch(e => e);
export const deleteResource = async (type, id) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS}/${type}/${id}`).catch(e => e);

// ========================================================================
// EXPERTS
// ========================================================================

export const addExpert = async (orgId, data) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts`, data).catch(e => e);
export const getExpert = async (orgId, id) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}`).catch(e => e);
export const getExperts = async (orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts`).catch(e => e);
export const updateExpert = async (orgId, data, id) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}`, data).catch(e => e);
export const removeExpert = async (orgId, id) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}`).catch(e => e);

// Expert File/Finetune Endpoints
export const addExpertFile = async (orgId, data, id) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/file`, data).catch(e => e);
export const removeExpertFile = async (orgId, id, file_id) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/file/${file_id}`).catch(e => e);
export const addExpertFinetune = async (orgId, data, id) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/finetune`, data).catch(e => e);
export const removeExpertFinetune = async (orgId, id, fintune_id) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/finetune/${fintune_id}`).catch(e => e);

// ========================================================================
// RESOURCE MANAGEMENT (OPERATIONS RESOURCES)
// ========================================================================

export const getResource = async (type, id) =>
  await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/${id}`).catch(e => e);
export const getResources = async (type, owner, filter = "") =>
  await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/${owner}/list${filter}`).catch(e => e);
export const findResources = async (type, field, value = "") =>
  await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/find-by/${field}/${value}`).catch(e => e);
export const getResourcesURI = async (uri) =>
  await apiClient.get(`/${uri}`).catch(e => e);
export const setResource = async (type, data) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}`, { data }).catch(e => e);
export const updateResource = async (orgId, type, config, id) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/${type}/${id}/config`, { config }).catch(e => e);

export const addStorageResource = async (file, owner, type, orgId) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('owner', owner);
  return await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/${type}`, formData).catch(e => e);
};

// ========================================================================
// OTHER ENDPOINTS
// ========================================================================

export const saveToOpenAI = async (type, id, data) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}/${id}/extref/openai`, { data }).catch(e => e);
export const deleteFromOpenAI = async (type, id) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS}/${type}/${id}/extref/openai`).catch(e => e);

export const findConversations = async (orgId = "") =>
  await apiClient.get(`/organizations/${orgId}/conversations`).catch(e => e);

// ========================================================================
// FILES & FINETUNES
// ========================================================================

export const deleteFile = async (id, orgId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_OPERATIONS_FILE}/${id}`).catch(e => e);

export const purgeFiles = async (ids) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS}/file/batch/purge?files=${ids.join(',')}`).catch(e => e);

export const getFiles = async (orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/files`).catch(e => e);

export const getFile = async (orgId, fileId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/files/${fileId}`).catch(e => e);
// Finetunes Endpoints

// List all finetunes for an organization
export const getFinetunes = async (orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes`).catch(e => e);

// Create a new finetune
export const createFinetune = async (data, orgId) =>
  await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes`, data).catch(e => e);

// Get a single finetune by its ID
export const getFinetune = async (finetuneId, orgId) =>
  await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes/${finetuneId}`).catch(e => e);

// Update a finetune by its ID
export const updateFinetune = async (finetuneId, data, orgId) =>
  await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes/${finetuneId}`, data).catch(e => e);

// Delete a single finetune by its ID
export const deleteFinetune = async (finetuneId, orgId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes/${finetuneId}`).catch(e => e);

// Delete multiple finetunes by their IDs (batch delete)
// Note: The Swagger spec expects a query parameter "finetunes"
// which should be a comma-separated list of IDs.
export const deleteFinetunes = async (ids, orgId) =>
  await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes?finetunes=${ids.join(',')}`).catch(e => e);


// ========================================================================
// CHAT ENDPOINTS
// ========================================================================

export const getCompletionStream = async (input, expert, onMessageCallback, onStreamEndCallback) => {
  const url = new URL(`${TARGET_BASE}/${API_VERSION}${ENDPOINT_COMPLETIONS_CHATGPT}/stream`);
  url.searchParams.append('input', JSON.stringify(input));
  url.searchParams.append('expert', JSON.stringify(expert));
  url.searchParams.append('token', await auth.currentUser.getIdToken());

  const eventSource = new EventSource(url.toString());
  eventSource.onmessage = event => onMessageCallback(JSON.parse(event.data));
  eventSource.onerror = error => {
    console.error('EventSource failed:', error);
    eventSource.close();
    onStreamEndCallback();
  };

  return () => eventSource.close();
};

export const addMessageToConversation = async (id, message = '', isUser, data = {}) =>
  await apiClient.post(`${ENDPOINT_CONVERSATIONS_API}/${id}/message`, { message, isUser, data }).catch(e => e);
export const addMessageToThread = async (id, data) =>
  await apiClient.post(`${ENDPOINT_THREAD_API}/${id}`, data).catch(e => e);
export const clearMessages = async (id) =>
  await apiClient.get(`${ENDPOINT_CONVERSATIONS_API}/${id}/clear`).catch(e => e);
export const sendMessageToConversation = async (id, message, onMessageCallback, onStreamEndCallback) => {
  const token = await auth.currentUser.getIdToken();
  const url = new URL(`${TARGET_BASE}/${API_VERSION}${ENDPOINT_CONVERSATIONS_API}/${id}/stream`);
  url.searchParams.append('message', JSON.stringify(message));
  url.searchParams.append('token', token);
  url.searchParams.append('apiauth', userJWTToken);

  const eventSource = new EventSource(url.toString());
  eventSource.onmessage = event => onMessageCallback(JSON.parse(event.data));
  eventSource.onerror = error => {
    console.error('EventSource failed:', error);
    eventSource.close();
    onStreamEndCallback();
  };

  return () => eventSource.close();
};

// ========================================================================
// TTS & STT
// ========================================================================

export const getSpeech = async (provider, data) =>
  await apiClient.post(`/tts/${provider}`, data, { responseType: 'blob' })
    .then(response => window.URL.createObjectURL(new Blob([response.data])))
    .catch(e => e);

export const transcribeAudio = async (audio) =>
  await apiClient.post(`${ENDPOINT_STT_OPENAI}/`, audio).catch(e => e);

// ========================================================================
// API KEYS & APP PASSWORD
// ========================================================================

export const setAppPassword = async (password, email = '') =>
  await apiClient.post(`${ENDPOINT_USER}/${auth.currentUser.uid}/apppass`, { email: email ? email : auth.currentUser.email, password }).catch(e => e);
//export const deleteAppPassword = async (id) => await apiClient.delete(`${ENDPOINT_USER}/${auth.currentUser.uid}/apppass`).catch(e => e);

export const getOpenAIKey = async (id) =>
  await apiClient.get(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`).catch(e => e);
export const setOpenAIKey = async (id, key) =>
  await apiClient.post(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`, { key }).catch(e => e);
export const deleteOpenAIKey = async (id) =>
  await apiClient.delete(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`).catch(e => e);
