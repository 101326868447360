import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  TextField,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { UserContext } from '../UserProvider';
import { createOrganization, listOrganizations } from '../Utilities/apiConnector';

function AddOrganization() {
  const { userData } = useContext(UserContext);
  const { register, handleSubmit } = useForm();
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await listOrganizations();
        // Assuming the API returns the organizations in response.data
        setOrganizations(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };

    fetchOrganizations();
  }, []);

  const onSubmit = async (data) => {
    try {
      // Parse additional fields if provided (they are optional)
      const usersArray = typeof data.users === 'string'
        ? data.users.split(',').map(user => user.trim())
        : [];
      const groupsArray = typeof data.groups === 'string'
        ? data.groups.split(',').map(group => group.trim())
        : [];
      const rolesArray = typeof data.roles === 'string'
        ? data.roles.split(',').map(role => role.trim())
        : [];

      const organizationData = {
        name: data.name,
        users: usersArray,
        groups: groupsArray,
        roles: rolesArray,
        // You can include the current organization from userData if needed:
        owner: userData.currentOrganization
      };

      await createOrganization(organizationData);
      console.log('Organization added successfully');

      // Optionally, refresh the organization list after creation.
      const response = await listOrganizations();
      setOrganizations(response.data);
    } catch (error) {
      console.error('Error adding organization:', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Add Organization
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Organization Name"
          name="name"
          autoComplete="name"
          autoFocus
          {...register('name')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="users"
          label="Users (comma separated)"
          name="users"
          autoComplete="users"
          {...register('users')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="groups"
          label="Groups (comma separated)"
          name="groups"
          autoComplete="groups"
          {...register('groups')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="roles"
          label="Roles (comma separated)"
          name="roles"
          autoComplete="roles"
          {...register('roles')}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Add Organization
        </Button>
      </form>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Existing Organizations
      </Typography>
      <List>
        {organizations.map((org) => (
          <ListItem key={org.id}>
            <ListItemText primary={org.name} />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default AddOrganization;
