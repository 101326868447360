import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Grid, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField,
  Typography
} from '@mui/material';
import SchemaList from '../Components/SchemaList';
import { UserContext } from '../UserProvider';
import { createSchema } from '../Utilities/apiConnector';

function Schemas() {
  const { orgId } = useParams();
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [schemaName, setSchemaName] = useState('');
  const [techName, setTechName] = useState('');
  const [schemaType, setSchemaType] = useState('report_validator');
  const [parserTemplate, setParserTemplate] = useState('westmec-01');

  const handleOpenCreateDialog = () => {
    setSchemaName('');
    setTechName('');
    setSchemaType('report_validator');
    setParserTemplate('westmec-01');
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const handleAddSchema = async () => {
    // Validate required fields
    if (!techName.trim()) {
      alert('Tech Name is required.');
      return;
    }

    // Use the current organization from userData if needed
    const currentOrg = userData?.currentOrganization || orgId;
    
    // Create the schema object according to the specified structure
    const newSchema = {
      name: schemaName.trim() || "New Schema",
      techName: techName.trim(),
      owner: currentOrg,
      type: schemaType.trim() || "report_validator",
      parserTemplate: parserTemplate.trim() || "westmec-01",
      schema: {  // This is an object, not a JSON string
        type: "object",
        properties: {}
      }
    };

    try {
      // Create the schema via API
      const response = await createSchema(currentOrg, newSchema);
      
      // Navigate to the schema detail page if successful
      if (response && response.data && response.data.id) {
        navigate(`/organizations/${currentOrg}/schema/${response.data.id}`);
      } else {
        console.error("Error: no ID returned from createSchema", response);
      }
    } catch (error) {
      console.error("Error creating schema:", error);
      alert(`Error creating schema: ${error.message || 'Unknown error'}`);
    } finally {
      setOpenCreateDialog(false);
    }
  };

  return (
    <Grid container sx={{ p: 3 }} spacing={2} direction="column">
      {userData && userData.currentOrganization && (
        <Box>
          <Grid sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenCreateDialog}
            >
              Add Schema
            </Button>
          </Grid>
          <Grid>
            <SchemaList />
          </Grid>
        </Box>
      )}

      {/* Create Schema Dialog */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create a New Schema</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
            Enter schema details:
          </Typography>
          
          {/* Schema Name */}
          <TextField
            label="Schema Name"
            value={schemaName}
            onChange={(e) => setSchemaName(e.target.value)}
            fullWidth
            margin="dense"
            sx={{ mb: 2 }}
          />
          
          {/* Tech Name - Required */}
          <TextField
            label="Tech Name (required)"
            value={techName}
            onChange={(e) => setTechName(e.target.value)}
            fullWidth
            margin="dense"
            required
            error={!techName.trim()}
            helperText={!techName.trim() ? "Tech Name is required" : ""}
            sx={{ mb: 2 }}
          />
          
          {/* Schema Type */}
          <TextField
            label="Schema Type"
            value={schemaType}
            onChange={(e) => setSchemaType(e.target.value)}
            fullWidth
            margin="dense"
            placeholder="report_validator"
            sx={{ mb: 2 }}
          />
          
          {/* Parser Template */}
          <TextField
            label="Parser Template"
            value={parserTemplate}
            onChange={(e) => setParserTemplate(e.target.value)}
            fullWidth
            margin="dense"
            placeholder="westmec-01"
            sx={{ mb: 2 }}
          />
          
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
            A default empty schema will be created. You can edit the schema details in the next screen.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="inherit">Cancel</Button>
          <Button 
            onClick={handleAddSchema} 
            variant="contained" 
            color="primary"
            disabled={!techName.trim()}
          >
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default Schemas;