import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
// Replace firestoreConnector imports with apiConnector functions
import { getFinetunes, deleteFinetune, removeExpertFinetune, getExperts } from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        fullWidth
      />
    </Box>
  );
}

export default function FinetuneList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [finetunes, setFinetunes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedFinetunes, setSelectedFinetunes] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { userData } = useContext(UserContext);

  const handleMouseClick = (event, row) => {
    if (event.ctrlKey || event.button === 1) { // Middle mouse button
      window.open(`/organizations/${orgId}/finetune/${row.id}`, '_blank');
    } else if (event.button === 0) { // Left mouse button
      navigate(`/organizations/${orgId}/finetune/${row.id}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Assuming getFinetunes returns a response object with the finetunes in data
      const response = await getFinetunes(orgId);
      // If your API returns { data: [...] } then adjust accordingly:
      setFinetunes(response.data || response);
    };
    fetchData();
  }, [orgId]);

  const handleEdit = (row) => {
    navigate(`/organizations/${orgId}/finetune/${row.id}`);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    // Retrieve experts using getExperts so that any references to the finetune are also cleaned up.
    const expertsResponse = await getExperts(orgId);
    const experts = expertsResponse.data || expertsResponse;
  
    const promises = selectedFinetunes.map(f =>
      deleteFinetune(f.id, orgId).then(() => {
        // For each expert, if their resources include this finetune, remove it via the API call.
        experts.forEach(expert => {
          if (expert.resources?.finetunes?.includes(f.id)) {
            removeExpertFinetune(orgId, expert.id, f.id);
          }
        });
      })
    );
    
    await Promise.all(promises);
    setSelectedFinetunes([]);
    setOpenDeleteDialog(false);
    // Optionally, refresh the list after deletion:
    const refreshResponse = await getFinetunes(orgId);
    setFinetunes(refreshResponse.data || refreshResponse);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedFinetunes(finetunes.filter(f => newSelection.includes(f.id)));
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'role', headerName: 'Role', flex: 0.5 },
    { 
      field: 'query', 
      headerName: 'Content', 
      flex: 1, 
      renderCell: (params) => (params.row.query ? params.row.query : params.row.instructions) 
    },
    { 
      field: 'action', 
      headerName: 'Action', 
      flex: 0.5, 
      renderCell: (params) => (
        <Button 
          variant="contained" 
          color="primary" 
          // onClick={(e) => { 
          //   e.stopPropagation(); 
          //   handleEdit(params.row); 
          // }}>
          onMouseDownCapture={(e) => {e.preventDefault()}}
          onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, params.row);}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, params.row);} else {navigate(`/organizations/${orgId}/finetune/${params.row.id}`);}}}
          >
          Edit
        </Button>
      )
    }
  ];

  const filteredRows = finetunes.filter((row) => {
    return row.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" component="h1" sx={{ m: 2 }}>Finetunes List</Typography>
        {selectedFinetunes.length > 0 && (
          <Button 
            sx={{ m: 2 }} 
            variant="contained" 
            color="secondary" 
            startIcon={<DeleteIcon />} 
            onClick={handleDelete}>
            Delete
          </Button>
        )}
      </Box>
      <Box sx={{ m: 2, maxWidth: '1000px', overflow: 'hidden' }}>
        <DataGrid 
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{ toolbar: { value: searchText, onChange: (e) => setSearchText(e.target.value) } }}
          rows={filteredRows} 
          columns={columns} 
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedFinetunes.map(f => f.id)}
          getRowId={(row) => row.id}
          disableSelectionOnClick
        />
      </Box>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Finetune</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selectedFinetunes.length} item(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
