import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { TextField, Button, Grid, Paper, Typography, Container, Alert } from '@mui/material';
import { UserContext } from '../UserProvider'; // Make sure the path is correct

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { setUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset the error message on a new attempt

    try {
      const credentials = await signInWithEmailAndPassword(auth, email, password);
      //console.log("credentials:", credentials)
      if (credentials.user) {
        // Set the user context
        setUser({ 
            name: credentials.user.displayName, 
            email: credentials.user.email 
        });

        // User is signed in, redirect to user panel
        navigate('/dashboard');
      } else {
        // No user associated with the given email and password
        console.error('No user associated with the given email and password');
        setError('No user associated with the given email and password');
      }
    } catch (error) {
      // Failed to sign in
      console.error('Error signing in', error);
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '10px' }}>
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
