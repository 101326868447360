import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import { getConversations, getConversationsByModule, removeConversation } from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

export default function ChatTable({ selectedModuleId }) {
  const { userData } = useContext(UserContext);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchConversations = async (moduleId) => {
    try {
      setLoading(true);
      const conversationResponse = await getConversationsByModule(userData.currentOrganization, moduleId) //getConversationsByModule(userData.currentOrganization, selectedModuleId);
      setConversations(conversationResponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch conversations:', error);
      setLoading(false);
    }
  };

  const handleMouseClick = (event, path) => {
    if (event.ctrlKey || event.button === 1) { // Middle mouse button
      window.open(`/chats/${path}`, '_blank');
    } else if (event.button === 0) { // Left mouse button
      navigate(`/chats/${path}`);
    }
  };

  useEffect(() => {
    if (selectedModuleId) {
      fetchConversations(selectedModuleId);
    }
  }, [selectedModuleId]);

  const handleEdit = (id) => {
    navigate(`/chats/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await removeConversation(userData.currentOrganization, id)
      // Add delete conversation logic here if needed
      fetchConversations(selectedModuleId);
    } catch (error) {
      console.error('Failed to delete conversation:', error);
    }
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Chat</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conversations.map((conversation) => (
            <TableRow key={conversation.id}>
              <TableCell>
                <Typography variant="h6">{conversation.name || 'New Chat'}</Typography>
                <Typography variant="body2">ID: {conversation.id}</Typography>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, conversation.id);}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, conversation.id);} else {navigate(`/chats/${conversation.id}`);}}}
                  //onClick={() => handleEdit(conversation.id)}
                  style={{ marginRight: '10px' }}
                >
                  Open
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(conversation.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}