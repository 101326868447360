import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import FinetuneList from '../Components/FinetuneList';
import { UserContext } from '../UserProvider';
import { createFinetune } from '../Utilities/apiConnector';

function Finetunes() {
  const { orgId } = useParams();
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const handleAddFinetune = async (type) => {
    // Use the current organization from userData if needed.
    const currentOrg = userData?.currentOrganization || orgId;
    const newFinetune = {
      name: `New ${type}`,
      owner: currentOrg,
      instructions: ``,
      type,
      role: 'user',
    };

    try {
      // createFinetune should send a POST request to your backend finetune endpoint,
      // e.g., POST /organizations/{orgId}/finetunes with the new finetune data.
      const response = await createFinetune(newFinetune, currentOrg);
      // Assuming the API returns the new finetune ID in response.data.id:
      const id = response.data.id;
      navigate(`/organizations/${currentOrg}/finetune/${id}`);
    } catch (error) {
      console.error("Error creating finetune:", error);
    }
  };

  return (
    <Grid container sx={{ p: 3 }} spacing={2} direction="column">
      {userData && userData.currentOrganization && (
        <Box>
          <Grid sx={{ mt: 3 }}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddFinetune('finetune')}
              sx={{ mr: 1 }}
            >
              Add QA
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddFinetune('instructions')}
            >
              Add Instructions
            </Button>
          </Grid>
          <Grid>
            <FinetuneList />
          </Grid>
        </Box>
      )}
    </Grid>
  );
}

export default Finetunes;
