import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Button, TextField, Typography, FormControl,
  FormHelperText, Select, MenuItem, CircularProgress, Box, Container,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { UserContext } from '../UserProvider';
import { addExpert, removeExpert, getExperts } from '../Utilities/apiConnector';
import ModelToServiceMap from '../Utilities/ModelToServiceMap';

export default function ExpertsTable() {
  // Prefer orgId from the URL; fallback to the current organization from userData if not provided.
  const { orgId: orgIdParam } = useParams();
  const { userData } = useContext(UserContext);
  const organizationId = orgIdParam || userData.currentOrganization;
  
  const [searchText, setSearchText] = useState('');
  const [experts, setExperts] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Dialog state for creating a new Expert
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [expertName, setExpertName] = useState('');
  const [techName, setTechName] = useState(''); // new state for techName
  const [selectedVdb, setSelectedVdb] = useState('pinecone');
  const [selectedModel, setSelectedModel] = useState('gpt-4o');
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExperts = async () => {
      try {
        const response = await getExperts(organizationId);
        if (response && response.data) {
          setExperts(response.data);
        } else {
          console.error('No data returned from getExperts');
        }
      } catch (error) {
        console.error('Error fetching experts:', error);
      } finally {
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchExperts();
    } else {
      setLoading(false);
    }
  }, [organizationId]);

  // Handle mouse click navigation, supporting middle-click to open in a new tab.
  const handleMouseClick = (event, expertId) => {
    if (event.ctrlKey || event.button === 1) {
      window.open(`/organizations/${organizationId}/experts/${expertId}`, '_blank');
    } else if (event.button === 0) {
      navigate(`/organizations/${organizationId}/experts/${expertId}`);
    }
  };

  // Open the Create Expert dialog
  const handleOpenCreateDialog = () => {
    setExpertName('');
    setTechName(''); // reset techName when opening the dialog
    setSelectedVdb('pinecone');
    setSelectedModel('gpt-4o');
    setOpenCreateDialog(true);
  };

  // Close the Create Expert dialog
  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  // Create a new expert using the API, after validating techName input.
  const handleConfirmCreateExpert = async () => {
    if (!techName.trim()) {
      // If techName is empty, alert the user and do not proceed.
      alert('Tech Name is required.');
      return;
    }
    
    const aiprovider = ModelToServiceMap[selectedModel] || 'openai'; // Fallback
    const newExpertPayload = {
      name: expertName,
      techName, // include techName in the payload
      owner: organizationId,
      vdb: selectedVdb,
      config: {
        model: selectedModel,
        aiprovider,
        temp: 0.2,
        vdbTopK: 5,
        vdbScoreTreshold: 0,
        useHistory: true,
        prompt: {
          context: {
            role: 'user',
            enclosure: {
              start: '',
              end: ''
            }
          }
        },
        closingTag: '',
        commonDivider: ''
      }
    };

    try {
      const resp = await addExpert(organizationId, newExpertPayload);
      if (resp && resp.data && resp.data.id) {
        // On success, navigate to the new expert's page.
        navigate(`/organizations/${organizationId}/experts/${resp.data.id}`);
      } else {
        console.error('Error: no ID returned from API', resp);
      }
    } catch (error) {
      console.error('Error creating expert:', error);
    } finally {
      handleCloseCreateDialog();
    }
  };

  // Delete an expert and update local state.
  const handleDelete = async (id) => {
    try {
      const resp = await removeExpert(organizationId, id);
      console.log('Deleted expert response:', resp);
      setExperts((prevExperts) => prevExperts.filter((expert) => expert.id !== id));
    } catch (error) {
      console.error('Error deleting expert:', error);
    }
  };

  // Navigate to the expert edit page.
  const handleEdit = (id) => {
    navigate(`/organizations/${organizationId}/experts/${id}`);
  };

  // Update the search text for filtering experts.
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // Filter experts based on the search text.
  const filteredExperts = experts.filter((expert) =>
    expert.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Container>
        <Box display="flex" alignItems="center" my={2}>
          <Box flexGrow={1} mr={2}>
            <TextField
              variant="outlined"
              label="Search Experts"
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
            />
          </Box>
          <Button variant="contained" color="primary" onClick={handleOpenCreateDialog}>
            Create Expert
          </Button>
        </Box>
      </Container>
      
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Expert</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExperts.map((expert, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="h6">{expert.name}, {expert.vdb ?? "---"}</Typography>
                    <Typography variant="body2">techName: {expert.techName}</Typography>
                    <Typography variant="body2">id: {expert.id || ''}, model: {expert?.config?.model || 'gpt-4o'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onMouseDownCapture={(e) => {e.preventDefault()}}
                      onAuxClick={(e) => {if (e.button === 1) {e.preventDefault(); e.stopPropagation(); handleMouseClick(e, expert.id);}}}
onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, expert.id);} else {navigate(`/organizations/${organizationId}/experts/${expert.id}`);}}}
                      style={{ marginRight: '10px' }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(expert.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {filteredExperts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No experts found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create a new Expert</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextField
              label="Expert Name"
              value={expertName}
              onChange={(e) => setExpertName(e.target.value)}
              fullWidth
              margin="dense"
            />
          </Box>
          {/* New field for techName */}
          <Box mb={2}>
            <TextField
              label="Tech Name (required)"
              value={techName}
              onChange={(e) => setTechName(e.target.value)}
              fullWidth
              margin="dense"
              required
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <FormHelperText>VDB Selection</FormHelperText>
              <Select
                value={selectedVdb}
                onChange={(e) => setSelectedVdb(e.target.value)}
              >
                <MenuItem value="pinecone">pinecone</MenuItem>
                <MenuItem value="immersion">immersion</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <FormHelperText>Model Selection</FormHelperText>
              <Select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
              >
                {Object.keys(ModelToServiceMap).map((modelKey) => (
                  <MenuItem key={modelKey} value={modelKey}>
                    {modelKey}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="inherit">Cancel</Button>
          <Button onClick={handleConfirmCreateExpert} variant="contained" color="primary">
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
