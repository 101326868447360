import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  TextField, Typography, Box, Container, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { listModules, createModule, removeModule, listSchemas } from '../Utilities/apiConnector';

export default function ModulesTable() {
  const { orgId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [modules, setModules] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const navigate = useNavigate();

  // Dialog state for creating a new Module with required techName.
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [techName, setTechName] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [selectedSchemaId, setSelectedSchemaId] = useState('');

  useEffect(() => {
    loadData();
    loadSchemas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  // Additional debugging function to examine schemas in the console
  useEffect(() => {
  }, [schemas]);

  const loadData = async () => {
    try {
      const response = await listModules(orgId);
      // Assume response = { data: [...] }
      setModules(response);
    } catch (error) {
      console.error("Error loading modules:", error);
    }
  };

  const loadSchemas = async () => {
    try {
      const response = await listSchemas(orgId);
      
      // Check if response is in the format { data: [...] }
      const schemasData = response.data && Array.isArray(response.data) 
        ? response.data 
        : Array.isArray(response) 
          ? response 
          : [];
      
      setSchemas(schemasData);
      
      // Set default schema if available
      if (schemasData.length > 0) {
        setSelectedSchemaId(schemasData[0].id);
      } else {
        console.warn("No schemas found in the response");
      }
    } catch (error) {
      console.error("Error loading schemas:", error);
    }
  };

  const handleMouseClick = (event, path) => {
    if (event.ctrlKey || event.button === 1) { // Middle mouse button
      window.open(`/organizations/${orgId}/modules/${path}`, '_blank');
    } else if (event.button === 0) { // Left mouse button
      navigate(`/organizations/${orgId}/modules/${path}`);
    }
  };

  // Instead of immediately creating a module, open the create dialog.
  const handleCreate = () => {
    setTechName(''); // Reset techName before opening
    setModuleName(''); // Reset moduleName before opening
    // Default to first schema if available
    setSelectedSchemaId(schemas.length > 0 ? schemas[0].id : '');
    setOpenCreateDialog(true);
    
    // Check if there are no schemas
    if (schemas.length === 0) {
      console.warn("No schemas available. You'll need to create one first.");
    }
  };

  // Function to actually create the module once techName is provided.
  const handleConfirmCreateModule = async () => {
    if (!techName.trim()) {
      alert('Tech Name is required.');
      return;
    }
    
    if (!selectedSchemaId) {
      alert('Please select a schema.');
      return;
    }
    
    const newModule = {
      name: moduleName?.trim() ? moduleName : "New Module",
      owner: orgId,
      experts: [],
      summaryExpert: "",
      techName,
      reportValidatorSchema: selectedSchemaId
    };
    try {
      const created = await createModule(orgId, newModule);
      if (created && created.data && created.data.id) {
        navigate(`/organizations/${orgId}/modules/${created.data.id}`);
      } else {
        console.error("Error: no ID returned from createModule", created);
      }
    } catch (error) {
      console.error('Error creating new module:', error);
    } finally {
      setOpenCreateDialog(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeModule(orgId, id);
      await loadData();
    } catch (error) {
      console.error('Error deleting module:', error);
    }
  };

  const handleEnter = (id) => {
    navigate(`/organizations/${orgId}/modules/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSchemaChange = (event) => {
    setSelectedSchemaId(event.target.value);
  };

  // If no search text, show all modules. If there's search text, filter by name.
  const filteredModules = searchText && modules.data
    ? modules.data.filter(mod => mod.name.toLowerCase().includes(searchText.toLowerCase()))
    : modules.data || [];

  return (
    <>
      <TableContainer>
        <Paper>
          <Container>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 2, mb: 2 }}>
              <TextField
                variant="outlined"
                label="Search Modules"
                value={searchText}
                onChange={handleSearchChange}
                fullWidth
                margin="normal"
                sx={{ mr: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
              >
                Create Module
              </Button>
            </Box>
          </Container>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Module</TableCell>
                {/* <TableCell>Owner</TableCell> */}
                <TableCell>Experts</TableCell>
                <TableCell>Summary Expert</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredModules?.map((module, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="h6">{module.name}</Typography>
                    <Typography variant="body2">techName: {module.techName}</Typography>
                    <Typography variant="body2">id: {module.id}</Typography>
                  </TableCell>
                  {/* <TableCell>{module.owner}</TableCell> */}
                  <TableCell>
                    {Array.isArray(module.experts) && module.experts.length > 0 
                      ? module.experts.join(', ') 
                      : '---'}
                  </TableCell>
                  <TableCell>{module.summaryExpert || '---'}</TableCell>
                  <TableCell>
                    <Box display="flex" gap="10px">
                      <Button
                        variant="contained"
                        color="primary"
                        onMouseDownCapture={(e) => {e.preventDefault()}}
                        onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, module.id);}}}
                        onClick={(e) => {if (e.ctrlKey || e.metaKey) {e.preventDefault();handleMouseClick(e, module.id);} else {navigate(`/organizations/${orgId}/modules/${module.id}`);}}}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(module.id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>

      {/* Create Module Dialog */}
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Create a New Module</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextField
              label="Module Name"
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
              fullWidth
              margin="dense"
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Tech Name (required)"
              value={techName}
              onChange={(e) => setTechName(e.target.value)}
              fullWidth
              margin="dense"
              required
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth margin="dense" required>
              <InputLabel id="schema-select-label">Report Validator Schema</InputLabel>
              <Select
                labelId="schema-select-label"
                value={selectedSchemaId}
                label="Report Validator Schema"
                onChange={handleSchemaChange}
                error={schemas.length === 0}
              >
                {schemas.length > 0 ? (
                  schemas.map((schema) => (
                    <MenuItem key={schema.id} value={schema.id}>
                      ID: {schema.id} | {schema.name} | TechName: {schema.techName} | Type: {schema.type} | Parser: {schema.parserTemplate}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value="">
                    No schemas available
                  </MenuItem>
                )}
              </Select>
              {schemas.length === 0 && (
                <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                  No schemas found. Please create a schema first.
                </Typography>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)} color="inherit">Cancel</Button>
          <Button 
            onClick={handleConfirmCreateModule} 
            variant="contained" 
            color="primary"
            disabled={!techName.trim() || !selectedSchemaId}
          >
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}