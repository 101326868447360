class EventManager {
    constructor() {
        this.subscribers = {};
    }

    subscribe(event, callback) {
        if (!this.subscribers[event]) {
            this.subscribers[event] = [];
        }
        this.subscribers[event].push(callback);
    }

    unsubscribe(event, callback) {
        let subscriberIndex = this.subscribers[event].indexOf(callback);
        if (subscriberIndex > -1) {
            this.subscribers[event].splice(subscriberIndex, 1);
        }
    }

    publish(event, data) {
        if (this.subscribers[event]) {
            this.subscribers[event].forEach(callback => callback(data));
        }
    }
}

export const eventManager = new EventManager();
