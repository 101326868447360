import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Button, 
  TextField, 
  Paper, 
  Typography, 
  Container, 
  AppBar, 
  Toolbar, 
  Box, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions,
  Grid
} from '@mui/material';
import { getSchema, updateSchema, removeSchema } from '../../Utilities/apiConnector';
import { UserContext } from '../../UserProvider';

// Helper function to sanitize incoming data
const sanitizeSchemaData = (rawData) => {
  // Ensure schema is properly handled as an object or string for editing
  let schemaContent = rawData.schema;
  
  // If schema is an object, convert to a formatted JSON string for editing
  if (typeof schemaContent === 'object' && schemaContent !== null) {
    schemaContent = JSON.stringify(schemaContent, null, 2);
  } else if (typeof schemaContent === 'string') {
    // Ensure it's valid JSON
    try {
      JSON.parse(schemaContent);
    } catch (e) {
      schemaContent = JSON.stringify({type: "object", properties: {}}, null, 2);
    }
  } else {
    // Default empty schema
    schemaContent = JSON.stringify({type: "object", properties: {}}, null, 2);
  }
  
  return {
    name: rawData.name || "",
    techName: rawData.techName || "",
    type: rawData.type || "report_validator",
    parserTemplate: rawData.parserTemplate || "westmec-01",
    schema: schemaContent
  };
};

function Schema() {
  const { schemaId } = useParams();
  const [data, setData] = useState({ 
    name: "", 
    techName: "", 
    type: "report_validator", 
    parserTemplate: "westmec-01", 
    schema: JSON.stringify({type: "object", properties: {}}, null, 2)
  });
  const [update, setUpdate] = useState({ 
    name: "", 
    techName: "", 
    type: "report_validator", 
    parserTemplate: "westmec-01", 
    schema: JSON.stringify({type: "object", properties: {}}, null, 2)
  });
  const [inSync, setInSync] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  
  // Use the organization from userData
  const orgId = userData?.currentOrganization;

  useEffect(() => {
    const loadData = async () => { 
      if (!orgId) return;
      return await getSchema(orgId, schemaId);
    };
    
    const fetchData = async () => {
      const response = await loadData();
      if(response && response.data) {
        const sanitized = sanitizeSchemaData(response.data);
        setData(sanitized);
        setUpdate(sanitized);
      }
    };
    
    fetchData();
  }, [schemaId, orgId]);

  const handleSave = async () => {
    if (!orgId) return;
    
    // Validate required fields
    if (!update.techName.trim()) {
      alert('Tech Name is required.');
      return;
    }
  
    try {
      // Create updated data according to Swagger spec
      const updatedData = {
        name: update.name,
        techName: update.techName,
        parserTemplate: update.parserTemplate,
        schema: JSON.parse(update.schema)
      };
      
      await updateSchema(orgId, schemaId, updatedData);
      
      // Update the base data and sync status
      // Note: We still store the complete data locally
      setData({...update});
      setInSync(true);
    } catch (error) {
      console.error("Error updating schema:", error);
      alert(`Error updating schema: ${error.message || 'Unknown error'}`);
    }
  };

  const handleNameChange = (name) => { 
    setUpdate({ ...update, name });
    setInSync(checkInSync({ ...update, name }));
  };

  const handleTechNameChange = (techName) => { 
    setUpdate({ ...update, techName });
    setInSync(checkInSync({ ...update, techName }));
  };

  const handleTypeChange = (type) => { 
    setUpdate({ ...update, type });
    setInSync(checkInSync({ ...update, type }));
  };

  const handleParserTemplateChange = (parserTemplate) => { 
    setUpdate({ ...update, parserTemplate });
    setInSync(checkInSync({ ...update, parserTemplate }));
  };

  const handleSchemaChange = (schema) => { 
    setUpdate({ ...update, schema });
    setInSync(checkInSync({ ...update, schema }));
  };

  const checkInSync = (updatedData) => {
    return data.name === updatedData.name && 
           data.techName === updatedData.techName && 
           data.type === updatedData.type &&
           data.parserTemplate === updatedData.parserTemplate &&
           data.schema === updatedData.schema;
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirm = async () => {
    if (!orgId) return;
    
    try {
      await removeSchema(orgId, schemaId);
      setOpenDeleteDialog(false);
      navigate(`/organizations/${orgId}/schemas`);
    } catch (error) {
      console.error("Error deleting schema:", error);
      alert(`Error deleting schema: ${error.message || 'Unknown error'}`);
    }
  };

  // Simple validation for JSON format
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      {/* Fixed header widget showing the schema name */}
      <AppBar position="sticky" color="default" elevation={2}>
        <Toolbar>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div">
              {update.name || "Schema Details"}
            </Typography>
            <Box>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleDeleteClick}
                sx={{ mr: 2 }}
              >
                Delete
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSave} 
                disabled={inSync || !isValidJson(update.schema) || !update.techName.trim()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Container>
        {data &&
          <Paper elevation={3} sx={{ p: 2, m: 2 }}>
            <Typography variant="h5" component="h1" sx={{ mb: 3 }}>Schema Configuration</Typography>
            
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField 
                  value={update.name} 
                  onChange={(e) => handleNameChange(e.target.value)} 
                  label="Name" 
                  fullWidth 
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField 
                  value={update.techName} 
                  onChange={(e) => handleTechNameChange(e.target.value)} 
                  label="Tech Name (required)" 
                  fullWidth 
                  required
                  error={!update.techName.trim()}
                  helperText={!update.techName.trim() ? "Tech Name is required" : ""}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField 
                  value={update.type} 
                  onChange={(e) => handleTypeChange(e.target.value)} 
                  label="Schema Type" 
                  fullWidth
                  placeholder="report_validator" 
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField 
                  value={update.parserTemplate} 
                  onChange={(e) => handleParserTemplateChange(e.target.value)} 
                  label="Parser Template" 
                  fullWidth
                  placeholder="westmec-01" 
                />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                  Schema Definition (JSON)
                </Typography>
                <TextField
                  value={update.schema}
                  onChange={(e) => handleSchemaChange(e.target.value)}
                  fullWidth
                  multiline
                  error={!isValidJson(update.schema)}
                  helperText={!isValidJson(update.schema) ? "Invalid JSON format" : ""}
                  variant="outlined"
                  InputProps={{ style: { fontFamily: 'monospace', height: 'auto' } }}
                  minRows={10}
                  maxRows={20}
                />
              </Grid>
            </Grid>
          </Paper>
        }
      </Container>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Schema</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this schema? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Schema;