import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserProvider';
import { Navigate } from 'react-router-dom';
import { getUser } from './Utilities/apiConnector';

const ProtectedComponent = ({ children, roles }) => {
  const { userData } = useContext(UserContext);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUserRole(userData.role);
    setLoading(false);
  }, [userData]);

  if (loading) {
    return null;
  }

  if (userData && userRole && roles.includes(userRole)) {
    return children;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default ProtectedComponent;