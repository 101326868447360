import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Collapse,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { UserContext } from '../UserProvider';
import { listOrganizations, deleteOrganization } from '../Utilities/apiConnector';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StripedTableRow = styled(TableRow)(({ index }) =>
  index % 2 ? { background: '#f7f7f7' } : {}
);

function OrganizationsTable() {
  const [organizations, setOrganizations] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await listOrganizations();
        setOrganizations(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };

    fetchOrganizations();
  }, [userData]);

  const handleRowClick = (orgId) => {
    navigate(`${orgId}`);
  };

  const handleDeleteClick = (orgId) => {
    setSelectedOrgId(orgId);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteOrganization(selectedOrgId);
      setOrganizations(organizations.filter((org) => org.id !== selectedOrgId));
      setConfirmDeleteOpen(false);
      setSelectedOrgId(null);
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };

  const handleDeleteCancel = () => {
    setSelectedOrgId(null);
    setConfirmDeleteOpen(false);
  };

  const toggleExpand = (orgId, event) => {
    // Prevent row click navigation when toggling expand
    event.stopPropagation();
    setExpandedRows((prev) => ({
      ...prev,
      [orgId]: !prev[orgId],
    }));
  };

  return (
    <Box mt={4} p={2} component={Paper}>
      <Typography variant="h6" component="h1" gutterBottom>
        Organizations
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Organization</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.length > 0 ? (
              organizations.map((org, index) => (
                // We use both rows with unique keys
                <React.Fragment key={org.id}>
                  <StripedTableRow
                    hover
                    onClick={() => handleRowClick(org.id)}
                    index={index}
                    key={`org-${org.id}`}
                  >
                    <StyledTableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton
                          onClick={(e) => toggleExpand(org.id, e)}
                          size="small"
                          style={{
                            transform: expandedRows[org.id]
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                        <Box ml={1} component="span">
                          {org.name || `Organization ${org.id}`}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        style={{
                          color: '#f50057',
                          border: '1px solid #f50057',
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = '#f50057')
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = 'transparent')
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteClick(org.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StripedTableRow>
                  <TableRow key={`details-${org.id}`}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                      <Collapse in={expandedRows[org.id]} timeout="auto" unmountOnExit>
                        <Box margin={2}>
                          <Typography variant="subtitle1" gutterBottom>
                            Details
                          </Typography>
                          <pre>{JSON.stringify(org, null, 2)}</pre>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={2} style={{ textAlign: 'center' }}>
                  No organizations found
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={confirmDeleteOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this organization?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OrganizationsTable;
