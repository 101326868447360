import React, { useRef, useState } from 'react';
import { Button, LinearProgress, Grid, Paper } from '@mui/material';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { auth } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { addStorageResource } from '../Utilities/apiConnector'

function ObjectUploader({ type, owner }) {
  const fileInputRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [pending, setPending] = useState(false);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i]);
    }
  };

  const uploadFile = async (file) => {
    const user = auth.currentUser;
    if (!user) return; 
    setPending(true);
    await addStorageResource(file, owner, type);
    setPending(false);
  };

  // const uploadFile = async (file) => {
  //   const user = auth.currentUser;
  //   if (!user) {
  //     return;
  //   }

  //   const storage = getStorage();
  //   const fileName = file.name.split('.')[0];
  //   console.log(fileName);
  //   const storageRef = ref(storage, `${category}/${id}/${collectionName}/${fileName}/${file.name}`);
  //   console.log(`${category}/${id}/${collectionName}/${fileName}/${file.name}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     'state_changed',
  //     (snapshot) => {
  //       var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       setProgress(progress);
  //     },
  //     (error) => {
  //       console.log(error);
  //     },
  //     async () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
  //         onFileUpload(downloadURL, file);

  //         // Add metadata to Firestore
  //         const metadata = {
  //           owner: id,
  //           fileName: file.name,
  //           fileURL: downloadURL,
  //           fileRef: storageRef.fullPath,
  //           category: category,
  //           type,
  //           status: 'ready'
  //         };
  //         await addDoc(collection(db, collectionName), metadata);
  //         setProgress(0);
  //       });
  //     }
  //   );
  // };

  return (
    <>
        <Button variant="contained" color="primary" onClick={handleUploadClick}>
          Upload
        </Button>
        <input type="file" ref={fileInputRef} onChange={handleFileChange} multiple style={{ display: 'none' }} />
        {progress > 0 && <LinearProgress variant="determinate" value={progress} />}
    </>
  );
}

export default ObjectUploader;
