import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Box } from '@mui/material';
import { getResource } from '../Utilities/apiConnector';

export default function Image() {
  const { imageid } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => { return await getResource('image', imageid); };
    
    const fetchData = async () => {
      const data = await loadData();
      setData(data);
    };
    
    fetchData();
  }, []);

  return (
    <Container>
      <Typography variant="h5" component="h2">Image: {data?.data?.fileName || ''}</Typography>
      <Box
        component="img"
        src={data?.data?.fileURL || ''}
        alt={data?.data?.fileName || ''}
        sx={{ maxWidth: '40%', height: 'auto' }}
      />
    </Container>
  );
}