import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography, Paper, CircularProgress } from '@mui/material';
import { UserContext } from '../UserProvider';
import ModelToServiceMap from '../Utilities/ModelToServiceMap';

// API connectors
import {
  getExpert,
  updateExpert,
  addExpertFile,
  removeExpertFile,
  addExpertFinetune,
  removeExpertFinetune
} from '../Utilities/apiConnector';

import ResourceTable from '../Components/ResourceTable';
import ExpertConfig from '../Components/ExpertConfig';

import { eventManager } from '../Utilities/eventManager';
import { useError } from '../Components/contexts/ErrorContext';
import { useNotification } from '../Components/contexts/NotificationContext';

export default function Expert() {
  // Call useParams only once
  const { orgId: orgFromParams, expertId } = useParams();
  const { userData } = useContext(UserContext);
  const orgId = userData.currentOrganization || orgFromParams;
  
  const [data, setData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFinetunes, setSelectedFinetunes] = useState([]);
  const [saving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { showError } = useError();
  const { addNotification } = useNotification();

  // Global API error handler subscription
  useEffect(() => {
    const handleError = (error) => {
      showError(error);
    };
    eventManager.subscribe('api-error', handleError);
    return () => {
      eventManager.unsubscribe('api-error', handleError);
    };
  }, [showError]);

  // Load expert data via API
  useEffect(() => {
    const loadExpert = async () => {
      if (!expertId) {
        console.error('Undefined expertId');
        return;
      }
      try {
        const response = await getExpert(orgId, expertId);
        if (response && response.data) {
          const dataFromApi = response.data;
          setData(dataFromApi);
          setSelectedFiles(dataFromApi.resources?.files ?? []);
          setSelectedFinetunes(dataFromApi.resources?.finetunes ?? []);
        } else {
          console.error('Expert not found');
        }
      } catch (error) {
        showError(error);
      }
    };

    loadExpert();
  }, [expertId, orgId, refresh, showError]);

  /**
   * Transforms the data to match the required schema.
   * Only keys defined in the schema will be included.
   */
  const filterDataToScheme = (data) => {
    return {
      name: data.name,
      techName: data.techName,
      config: data.config
        ? {
            model: data.config.model,
            aiprovider: ModelToServiceMap[data.config.model],
            temp: data.config.temp,
            vdbTopK: data.config.vdbTopK,
            vdbScoreTreshold: data.config.vdbScoreTreshold,
            useHistory: data.config.useHistory,
            prompt: data.config.prompt
              ? {
                  context: data.config.prompt.context
                    ? {
                        role: data.config.prompt.context.role,
                        enclosure: {
                          start: data.config.prompt.context.enclosure?.start,
                          end: data.config.prompt.context.enclosure?.end
                        }
                      }
                    : undefined,
                  finetunes: data.config.prompt.finetunes
                    ? {
                        enclosure: {
                          start: data.config.prompt.finetunes.enclosure?.start,
                          end: data.config.prompt.finetunes.enclosure?.end
                        }
                      }
                    : undefined,
                  history: data.config.prompt.history
                    ? {
                        enclosure: {
                          start: data.config.prompt.history.enclosure?.start,
                          end: data.config.prompt.history.enclosure?.end
                        }
                      }
                    : undefined,
                  message: data.config.prompt.message
                    ? {
                        enclosure: {
                          start: data.config.prompt.message.enclosure?.start,
                          end: data.config.prompt.message.enclosure?.end
                        }
                      }
                    : undefined
                }
              : undefined,
            closingTag: data.config.closingTag,
            commonDivider: data.config.commonDivider
          }
        : undefined,
      resources: data.resources
        ? {
            files: data.resources.files || [],
            finetunes: data.resources.finetunes || [],
            objects: data.resources.objects || []
          }
        : undefined
    };
  };

  /**
   * Handles the updated data object from ExpertConfig
   */
  const handleSave = async (newData) => {
    try {
      // Filter the newData object so that it strictly follows the schema.
      const filteredData = filterDataToScheme(newData);
      await updateExpert(orgId, filteredData, expertId);
      setRefresh(!refresh);
      addNotification(`Expert ${expertId} updated`, 'success');
    } catch (error) {
      showError(error);
    }
  };

  /**
   * Syncs new resource selection with API for files or finetunes.
   */
  const handleResourcesSelected = async ({ name, selection }) => {
    setSaving(true);
  
    try {
      const oldSelection = data.resources?.[name] ?? [];
      const newSelection = selection;
  
      // Determine which items got added or removed
      const added = newSelection.filter((item) => !oldSelection.includes(item));
      const removed = oldSelection.filter((item) => !newSelection.includes(item));
  
      if (name === 'files') {
        // For each newly selected file -> call addExpertFile
        for (const fileId of added) {
          await addExpertFile(orgId, { fileid: fileId }, expertId);
        }
        // For each file removed -> call removeExpertFile
        for (const fileId of removed) {
          await removeExpertFile(orgId, expertId, fileId);
        }
      } else if (name === 'finetunes') {
        // For each newly selected finetune -> call addExpertFinetune
        for (const ftId of added) {
          await addExpertFinetune(orgId, { finetuneid: ftId }, expertId);
        }
        // For each finetune removed -> call removeExpertFinetune
        for (const ftId of removed) {
          await removeExpertFinetune(orgId, expertId, ftId);
        }
      }
  
      // Once updates are done, re-fetch the expert
      const expertRes = await getExpert(orgId, expertId);
      const expert = expertRes.data;
      if (!expert) {
        setSaving(false);
        return;
      }
  
      // Update expert state including the resources
      setData(expert);
      setSelectedFiles(expert.resources?.files ?? []);
      setSelectedFinetunes(expert.resources?.finetunes ?? []);
  
      // If the expert doesn’t use external OpenAI references, update vector DB if needed.
      if (!expert?.externalRef?.openai && name !== 'finetunes') {
        await updateVDB([...expert.resources?.files]);
      }
    } catch (error) {
      showError(error);
    } finally {
      setSaving(false);
    }
  };

  /**
   * Updates vector DB with new content after clearing existing namespace.
   * Currently this function is a placeholder.
   */
  const updateVDB = async (selection) => {
    // Example:
    // await deleteNamespace(data.vdb ?? 'pinecone', expertId);
    // await Promise.all(selection.map(async (item) => {
    //   await addVectors(item, data.vdb ?? 'pinecone', expertId);
    // }));
  };

  /**
   * Forces a "reload" for re-indexing all resources in the vector DB.
   */
  const reloadExpert = async () => {
    setSaving(true);

    try {
      const expertRes = await getExpert(orgId, expertId);
      const expert = expertRes.data;
      if (!expert) {
        setSaving(false);
        return;
      }
      const allFiles = expert.resources?.files;
      if (allFiles) {
        await updateVDB([...allFiles]);
      }
    } catch (error) {
      showError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container sx={{ p: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ExpertConfig data={data} onUpdate={handleSave} onReload={reloadExpert} />
        </Grid>
      </Grid>

      {saving ? (
        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40vh',
              height: '5vh',
              m: 5,
              p: 1
            }}
          >
            <Typography variant="h6" component="span" sx={{ mr: 2 }}>
              Saving...
            </Typography>
            <CircularProgress />
          </Paper>
        </Grid>
      ) : (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* FILES */}
              <ResourceTable
                header="Files"
                onSelect={(selection) => handleResourcesSelected({ name: 'files', selection })}
                selected={selectedFiles}
                filter={data?.vdb === 'openai' ? ['externalRef.openai'] : []}
              />
            </Grid>
            <Grid item xs={12}>
              {/* FINETUNES */}
              <ResourceTable
                header="Finetunes"
                onSelect={(selection) => handleResourcesSelected({ name: 'finetunes', selection })}
                selected={selectedFinetunes}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                External Ref: {JSON.stringify(data?.externalRef)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
}
