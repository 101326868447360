import React, { createContext, useContext, useState, useRef } from 'react';
import ErrorDialog from '../dialogs/ErrorDialog';

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const [errorConfig, setErrorConfig] = useState(null);
  const hasError = useRef(false);

  const showError = (errorResponse, callback) => {
    hasError.current = true;
    setErrorConfig({
      error: errorResponse,
      handleClose: () => {
        if (callback) callback();
        hideError();
      }
    });
  };

  const hideError = () => {
    if (errorConfig && errorConfig.handleClose) {
      errorConfig.handleClose();
    }
    setErrorConfig(null);
    hasError.current = false;
  };

  return (
    <ErrorContext.Provider value={{ showError, hideError, hasError }}>
      {children}
      <ErrorDialog config={errorConfig} />
    </ErrorContext.Provider>
  );
};
