import React, { useState, useEffect, useContext } from 'react';
import FileUploader from '../Components/FileUploader';
import { UserContext } from '../UserProvider';
import { Grid, Table, Stack, TableContainer } from '@mui/material';
import ImagesList from '../Components/ImagesList';

export default function Images() {
  const { userData } = useContext(UserContext);
  const [fileURL, setFileURL] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUploadTrigger, setFileUploadTrigger] = useState(0);

  const handleFileSelection = (files) => {
    setFileURL(files[0]?.url);
    setSelectedFiles(files);
  };

  return (
    <TableContainer>
      {userData && userData.currentOrganization && (
        <Stack>
          <ImagesList onSelectFiles={handleFileSelection} category={'organizations'} id={userData?.currentOrganization} type={"image"} collectionName={"images"} refreshKey={fileUploadTrigger} />
          <FileUploader owner={userData?.currentOrganization} type={"image"} />
        </Stack>
      )}
    </TableContainer>
  );
}