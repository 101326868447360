import React, { useContext } from 'react';
import { Typography, Button, Stack } from '@mui/material';
import { UserContext } from '../UserProvider';

function Dashboard() {
  const { user, userData } = useContext(UserContext);

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="h4">
        {user ? `Hello, ${userData?.displayName}! THIS IS V3!` : 'Not logged in'}
      </Typography>

      <Typography variant="h7">
        {user ? `Your role in org: ${userData?.role}` : 'Cannot determine role'}
      </Typography>

      <Typography variant="h7">
        {user ? `Are you superuser?: ${userData?.superUser ? 'YES' : 'NO'}` : 'Cannot determine role'}
      </Typography>

    </Stack>
  );
}

export default Dashboard;
