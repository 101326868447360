import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Button, Paper, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material';
import { UserContext } from '../UserProvider';
import { createConversation } from '../Utilities/apiConnector';
import ModuleSelector from '../Components/ModuleSelector';
import ChatTable from '../Components/ChatTable';

export default function Chats() {
  const { user, userData } = useContext(UserContext);
  const [selectedModuleId, setSelectedModuleId] = useState('');
  const navigate = useNavigate();

  // New states for the Chat Creation dialog
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [chatName, setChatName] = useState('');

  // Open the dialog if a module is selected, else alert the user.
  const handleCreateChat = () => {
    if (!selectedModuleId) {
      alert('Please select a module before creating a chat.');
      return;
    }
    // Open the dialog to enter a chat name
    setChatName('');
    setOpenChatDialog(true);
  };

  // Close the chat dialog
  const handleCloseChatDialog = () => {
    setOpenChatDialog(false);
  };

  // Confirm creation after chat name is entered
  const handleConfirmCreateChat = async () => {
    if (!chatName.trim()) {
      alert('Chat name is required.');
      return;
    }
    try {
      // Include chatName in the payload (assuming your API supports it)
      const newConversation = await createConversation(
        userData?.currentOrganization,
        {
          owner: user.uid,
          module: selectedModuleId,
          name: chatName
        }
      );
      if (newConversation && newConversation.data.id) {
        navigate(`/chats/${newConversation.data.id}`);
      } else {
        alert('Failed to create a new chat.');
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      alert('An error occurred while creating the chat. Please try again.');
    } finally {
      handleCloseChatDialog();
    }
  };

  const handleModuleSelect = (moduleId) => {
    setSelectedModuleId(moduleId);
  };

  return (
    <Container component={Paper} style={{ padding: '20px' }}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={10}>
          <ModuleSelector orgId={userData?.currentOrganization} onSelect={handleModuleSelect} />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateChat}
            fullWidth
          >
            Create Chat
          </Button>
        </Grid>
      </Grid>
      <ChatTable selectedModuleId={selectedModuleId} />

      {/* Chat Creation Dialog */}
      <Dialog open={openChatDialog} onClose={handleCloseChatDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create a new Chat</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextField
              label="Chat Name (required)"
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
              fullWidth
              margin="dense"
              required
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChatDialog} color="inherit">Cancel</Button>
          <Button onClick={handleConfirmCreateChat} variant="contained" color="primary">
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
