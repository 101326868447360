import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, MenuItem, Button, TextField, Grid, Paper, Typography, Container, AppBar, Toolbar, Box } from '@mui/material';
import { getFinetune, updateFinetune, deleteFinetune } from '../../Utilities/apiConnector';
import { UserContext } from '../../UserProvider';

const Type = {
  Finetune : 'finetune',
  Instructions : 'instructions'
};

const Roles = {
  System : 'system',
  Assistant : 'assistant',
  User : 'user'
};

const schema = {
  name: "",
  instructions: "",
  role: Roles.System,
  type: Type.Instructions,
};

// Helper function to sanitize incoming data
const sanitizeFinetuneData = (rawData) => {
  return {
    name: rawData.name,
    instructions: rawData.instructions,
    role: rawData.role,
    type: rawData.type,
  };
};

function Finetune() {
  
  // We only need finetuneId from URL; orgId is now obtained from userData.
  const { finetuneId } = useParams();
  const [data, setData] = useState(schema);
  const [update, setUpdate] = useState(schema);
  const [inSync, setInSync] = useState(true);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  
  // Use the organization from userData
  const orgId = userData?.currentOrganization;

  useEffect(() => {
    const loadData = async () => { 
      if (!orgId) return;
      return await getFinetune(finetuneId, orgId);
    };
    
    const fetchData = async () => {
      const response = await loadData();
      if(response && response.data) {
        const sanitized = sanitizeFinetuneData(response.data);
        setData(sanitized);
        setUpdate(sanitized);
      }
    };
    
    fetchData();
  }, [finetuneId, orgId]);

  const handleSave = async () => {
    if (!orgId) return;
    await updateFinetune(finetuneId, { ...data, ...update }, orgId);
    setInSync(true);
  };
  const handleNameChange = (name) => { 
    setUpdate({ ...update, name });
    setInSync(data.name === name);
  };
  const handleInstructionChange = (instructions) => { 
    setUpdate({ ...update, instructions });
    setInSync(data.instructions === instructions);
  };
  const handleQueryChange = (query) => { 
    setUpdate({ ...update, query });
    setInSync(data.query === query);
  };
  const handleResponseChange = (response) => { 
    setUpdate({ ...update, response });
    setInSync(data.response === response);
  };
  const handleRoleChange = (role) => { 
    setUpdate({ ...update, role });
    setInSync(data.role === role);
  };

  // Uncomment and implement delete functionality if needed.
  // const handleDelete = async () => {
  //   if (!orgId) return;
  //   await deleteFinetune(finetuneId, orgId);
  //   navigate(`/organizations/${orgId}/finetuning`);
  // };

  return (
    <>
      {/* Fixed header widget showing the instruction name */}
      <AppBar position="sticky" color="default" elevation={2}>
        <Toolbar>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography variant="h6" component="div">
              {update.name || "Instruction Name"}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Container>
        {data &&
          <Paper elevation={3} sx={{ p: 2, m: 2 }}>
            <Typography variant="h5" component="h1" sx={{ mb: 2 }}>{update.type}</Typography>
            <TextField value={update.name} onChange={(e) => handleNameChange(e.target.value)} label="Name" fullWidth sx={{ mb: 2 }}/>
            <Grid container spacing={2}>
              {update.type === Type.Finetune ? (
                <>
                  <Grid item xs={6}>
                    <TextField
                      value={update.query}
                      onChange={(e) => handleQueryChange(e.target.value)}
                      label="Query"
                      fullWidth
                      multiline
                      variant="outlined"
                      InputProps={{ style: { height: 'auto' } }}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={update.response}
                      onChange={(e) => handleResponseChange(e.target.value)}
                      label="Response"
                      fullWidth
                      multiline
                      variant="outlined"
                      InputProps={{ style: { height: 'auto' } }}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    value={update.instructions}
                    onChange={(e) => handleInstructionChange(e.target.value)}
                    label="Instructions"
                    fullWidth
                    multiline
                    variant="outlined"
                    InputProps={{ style: { height: 'auto' } }}
                    sx={{ marginBottom: 2 }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Select value={update.role ?? Roles.User} onChange={(e) => handleRoleChange(e.target.value)}>
                  <MenuItem value={Roles.System}>System</MenuItem>
                  <MenuItem value={Roles.Assistant}>Assistant</MenuItem>
                  <MenuItem value={Roles.User}>User</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={inSync} sx={{ mt: 2, mr: 2 }}>
                  Save
                </Button>
                {/* <Button variant="contained" color="secondary" onClick={handleDelete} sx={{ mt: 2 }}>
                  Delete
                </Button> */}
              </Grid>
            </Grid>
          </Paper>
        }
      </Container>
    </>
  );
}

export default Finetune;