import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { listSchemas, removeSchema } from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        fullWidth
      />
    </Box>
  );
}

export default function SchemaList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [schemas, setSchemas] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedSchemas, setSelectedSchemas] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [schemaToDelete, setSchemaToDelete] = useState(null);
  const { userData } = useContext(UserContext);

  const handleMouseClick = (event, row) => {
    if (event.ctrlKey || event.button === 1) { // Middle mouse button
      window.open(`/organizations/${orgId}/schema/${row.id}`, '_blank');
    } else if (event.button === 0) { // Left mouse button
      navigate(`/organizations/${orgId}/schema/${row.id}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await listSchemas(orgId);
      setSchemas(response.data || response);
    };
    fetchData();
  }, [orgId]);

  const handleEdit = (row) => {
    navigate(`/organizations/${orgId}/schema/${row.id}`);
  };

  const handleDeleteSelected = () => {
    setSchemaToDelete(null);
    setOpenDeleteDialog(true);
  };

  const handleDeleteSingle = (row) => {
    setSchemaToDelete(row);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (schemaToDelete) {
        // Single schema delete
        await removeSchema(orgId, schemaToDelete.id);
      } else {
        // Multiple schemas delete
        const promises = selectedSchemas.map(schema =>
          removeSchema(orgId, schema.id)
        );
        await Promise.all(promises);
      }
      
      setSelectedSchemas([]);
      setSchemaToDelete(null);
      setOpenDeleteDialog(false);
      
      // Refresh the list after deletion
      const refreshResponse = await listSchemas(orgId);
      setSchemas(refreshResponse.data || refreshResponse);
    } catch (error) {
      console.error("Error deleting schema(s):", error);
    }
  };

  const handleDeleteCancel = () => {
    setSchemaToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedSchemas(schemas.filter(s => newSelection.includes(s.id)));
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'techName', headerName: 'Tech Name', flex: 1 },
    { 
      field: 'updatedAt', 
      headerName: 'Last Updated', 
      flex: 1,
      valueFormatter: (params) => {
        if (!params.value) return '';
        return new Date(params.value).toLocaleString();
      }
    },
    { 
      field: 'actions', 
      headerName: 'Actions', 
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <Button 
            variant="contained" 
            color="primary"
            size="small"
            startIcon={<EditIcon />}
            onMouseDownCapture={(e) => {e.preventDefault()}}
            onAuxClick={(e) => {if (e.button === 1) {handleMouseClick(e, params.row);}}}
            onClick={(e) => {
              if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
                handleMouseClick(e, params.row);
              } else {
                navigate(`/organizations/${orgId}/schema/${params.row.id}`);
              }
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => handleDeleteSingle(params.row)}
          >
            Delete
          </Button>
        </Box>
      )
    }
  ];

  const filteredRows = schemas.filter((row) => {
    return (row.name || '').toLowerCase().includes(searchText.toLowerCase()) || 
           (row.techName || '').toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" component="h1" sx={{ m: 2 }}>Schemas List</Typography>
        {selectedSchemas.length > 0 && (
          <Button 
            sx={{ m: 2 }} 
            variant="contained" 
            color="secondary" 
            startIcon={<DeleteIcon />} 
            onClick={handleDeleteSelected}>
            Delete Selected ({selectedSchemas.length})
          </Button>
        )}
      </Box>
      <Box sx={{ m: 2, maxWidth: '1200px', overflow: 'hidden' }}>
        <DataGrid 
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{ toolbar: { value: searchText, onChange: (e) => setSearchText(e.target.value) } }}
          rows={filteredRows} 
          columns={columns} 
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedSchemas.map(s => s.id)}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
          autoHeight
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </Box>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Schema</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {schemaToDelete 
              ? `Are you sure you want to delete schema "${schemaToDelete.name}"?` 
              : `Are you sure you want to delete ${selectedSchemas.length} selected schema(s)?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}