import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  Stack,
  Paper,
  Chip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  getOrganization,
  updateOrganization,
  getOpenAIKey,
  setOpenAIKey,
  deleteOpenAIKey,
  listOrganizationMembers,
  addMemberToOrganization
} from '../../Utilities/apiConnector';

export default function Organisation() {
  const { orgId } = useParams();
  const [org, setOrg] = useState({});
  const [orgUpdate, setOrgUpdate] = useState({});
  const [key, setKey] = useState('');
  const [keySaved, setKeySaved] = useState(false);
  const [members, setMembers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // State for adding a new user
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get organization details using the new getOrganization call
        const orgResponse = await getOrganization(orgId);
        const organization = orgResponse.data;
        setOrg(organization);
        setOrgUpdate(organization);

        // Get OpenAI key status
        const keyStatusResponse = await getOpenAIKey(orgId);
        setKeySaved(keyStatusResponse.data);

        // Get organization members using the API call
        const membersResponse = await listOrganizationMembers(orgId);
        setMembers(membersResponse.data);
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };
    fetchData();
  }, [orgId, refresh]);

  const handleUpdate = (name, value) => {
    setOrgUpdate(prev => ({ ...prev, [name]: value }));
  };

  const handleSetOpenaiKey = (e) => {
    setKey(e.target.value);
  };

  const handleDeleteOpenaiKey = async () => {
    if (!keySaved) return;
    await deleteOpenAIKey(orgId);
    setRefresh(prev => !prev);
  };

  const handleSave = async () => {
    try {
      await updateOrganization(orgUpdate, orgId);
      if (key) await setOpenAIKey(orgId, key);
      setKey('');
      setRefresh(prev => !prev);
    } catch (error) {
      console.error('Error saving organization:', error);
    }
  };

  const handleAddUser = async () => {
    if (!newUserEmail || !newUserRole) return;
    try {
      await addMemberToOrganization(orgId, { email: newUserEmail, role: newUserRole });
      setNewUserEmail('');
      setNewUserRole('');
      setRefresh(prev => !prev); // Refresh member list after adding a member
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  return (
    <Container>
      <Paper sx={{ p: 1, mb: 1 }}>
        <Stack spacing={1}>
          <Typography variant="h4">{org?.name} Details</Typography>
          <TextField
            label="Organization Name"
            size="small"
            value={orgUpdate?.name || ''}
            onChange={(e) => handleUpdate('name', e.target.value)}
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Chip
              size="small"
              icon={keySaved ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
              label={keySaved ? 'Saved' : 'Missing'}
            />
            <TextField
              fullWidth
              label="OpenAI Key"
              size="small"
              value={key}
              onChange={handleSetOpenaiKey}
            />
            <Button variant="contained" color="error" disabled={!keySaved} onClick={handleDeleteOpenaiKey}>
              DELETE
            </Button>
          </Stack>
          <Button variant="contained" onClick={handleSave}>
            SAVE
          </Button>
        </Stack>
      </Paper>

      <Paper sx={{ p: 1, mb: 1 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>Organization Members</Typography>
        <Grid container spacing={1}>
          {members && members.length > 0 ? (
            members.map((member) => (
              <Grid item xs={12} key={member.user.id}>
                <Paper sx={{ p: 1 }}>
                  <Typography>
                    {member.user.email} – {member.role} ({member.state})
                  </Typography>
                </Paper>
              </Grid>
            ))
          ) : (
            <Typography>No members found.</Typography>
          )}
        </Grid>
      </Paper>

      <Paper sx={{ p: 1, mb: 1 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>Add New User</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            label="Email"
            size="small"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
          <TextField
            label="Role"
            size="small"
            value={newUserRole}
            onChange={(e) => setNewUserRole(e.target.value)}
          />
          <Button variant="contained" onClick={handleAddUser}>
            Add User
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
}
